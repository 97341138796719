/* eslint-disable @typescript-eslint/no-explicit-any */
import Logo from 'assets/astrabiz-logo.png';
import ErrorCard from 'components/ErrorCard';
import Loader from 'components/Loader';
import { TOKEN_COOKIE } from 'constants/const';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AGENT_FORGOT_PASSWORD_ROUTE, AGENT_ROUTE_BASE } from 'routes/path';
import { useAgentLoginMutation, useResendOtpMutation } from 'services/auth';
import cookie from 'services/cookie';
import { useAppDispatch } from 'store';
import { setEmail, setPassword } from 'store/auth';
import { LoginInput } from 'types/auth';
import AuthLayout from 'views/auth/AuthAgentLayout';
import VerifyEmail from 'views/auth/VerifyEmail';
import * as Yup from 'yup';

const AgentLogin = () => {
  const [error, setError] = useState<any>();
  const [page, setPage] = useState(0);
  const [source, setSource] = useState('login');

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const initialValues: LoginInput = { email: '', password: '' };

  const validationSchema: Yup.Schema<LoginInput> = Yup.object().shape({
    email: Yup.string()
      .email('please input a valid business email')
      .required('please input a valid business email'),
    password: Yup.string().required(),
  });

  const [login, { isLoading: isLoadingLogin }] = useAgentLoginMutation();
  const [resendOtp] = useResendOtpMutation();

  const handleSubmit = (data: LoginInput) => {
    login(data)
      .unwrap()
      .then((payload) => {
        dispatch(setEmail(data.email));
        dispatch(setPassword(data.password));
        if (payload.data.is_email_verified === false) {
          setSource('register');
          resendOtp({ email: data.email });
          setPage(1);
        } else if (payload.data.access_token) {
          cookie.set(TOKEN_COOKIE, {
            ...payload.data,
          });
        }
        navigate(AGENT_ROUTE_BASE);
      })
      .catch((error: any) => {
        dispatch(setEmail(data.email));
        setError(error);
      });
  };

  useEffect(() => {
    document.title = 'AstraBiz | Agent Login';
  }, []);

  return (
    <AuthLayout>
      {!page ? (
        <div className="p-6 mx-auto w-[80%]">
          <img src={Logo} alt="astravest-logo" />
          <p className="mt-16 text-2xl font-black">Login to your account</p>
          {error ? <ErrorCard text={error.data.message} /> : null}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: LoginInput) => handleSubmit(values)}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              handleBlur,
            }: FormikProps<LoginInput>) => (
              <Form onSubmit={handleSubmit}>
                <div className="mt-5 grid">
                  <label htmlFor="businessEmail" className="text-base font-medium">
                    Business Email Address*
                  </label>
                  <Field
                    name="businessEmail"
                    type="email"
                    className="border mt-2 p-3 rounded-xl placeholder:text-sm"
                    placeholder="business email address"
                    onBlur={handleBlur('email')}
                    onChange={handleChange('email')}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <p className="text-sm font-bold text-red-500">{errors.email}</p>
                  ) : null}
                </div>

                <div className="mt-3 grid">
                  <label htmlFor="password" className="text-base font-medium">
                    Password*
                  </label>
                  <Field
                    name="password"
                    className="border mt-2 p-3 rounded-xl placeholder:text-sm"
                    placeholder="************"
                    type="password"
                    onBlur={handleBlur('password')}
                    onChange={handleChange('password')}
                    value={values.password}
                  />
                  {errors.password && touched.password ? (
                    <p className="text-sm font-bold text-red-500">{errors.password}</p>
                  ) : null}
                </div>

                <Link
                  to={AGENT_FORGOT_PASSWORD_ROUTE}
                  className="mt-3 text-base text-astravest-blue font-medium cursor-pointer"
                >
                  Forgot password?
                </Link>
                {isLoadingLogin ? (
                  <Loader className="mt-8" />
                ) : (
                  <button
                    className="mt-8 w-full text-center bg-astravest-blue text-white py-3 rounded-xl text-xl font-bold"
                    type="submit"
                  >
                    Continue
                  </button>
                )}
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <VerifyEmail from={source} />
      )}
    </AuthLayout>
  );
};

export default AgentLogin;
