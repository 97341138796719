import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { transactionState } from 'types/Transaction';

const initialState: transactionState = {
  transaction: {},
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setTransaction: (state, { payload }: PayloadAction<transactionState>) => {
      state.transaction = payload;
    },
  },
});

export const transactionSelector = (state: RootState) => state.transaction;

export const { setTransaction } = transactionSlice.actions;

export default transactionSlice.reducer;
