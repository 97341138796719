import { FC } from 'react';

const ThreeIcon: FC = () => (
  <svg width="32" height="59" viewBox="0 0 32 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="31" rx="15.5" fill="#D0D0D0" />
    <path
      d="M15.048 15.356H14.136V14.556L17.672 11.068H12.184V10.044H19.128V10.972L15.704 14.38C17.848 14.492 19.384 15.852 19.384 17.932C19.384 20.204 17.72 21.692 15.4 21.692C13.144 21.692 11.624 20.3 11.624 18.012H12.76C12.76 19.66 13.784 20.652 15.4 20.652C17.112 20.652 18.216 19.564 18.216 17.9C18.216 16.332 17.096 15.356 15.048 15.356Z"
      fill="white"
    />
    <path d="M16.5 59V31" stroke="#D0D0D0" strokeWidth="2" />
  </svg>
);

export default ThreeIcon;
