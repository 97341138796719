import AuthBackgroundLines from 'assets/auth-background-lines.svg';
import SwapMockup from 'assets/swap_img.svg';

interface Props {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: Props) => {
  return (
    <div className="w-full grid md:grid-cols-2">
      <div className="h-screen overflow-y-scroll">{children}</div>
      <div className="bg-[#1174ED0D] h-screen relative hidden md:block">
        <img src={AuthBackgroundLines} alt="auth background lines" className="h-screen w-full" />
        <div className="absolute top-[20rem] left-[15rem] transform -translate-x-[8rem] -translate-y-1/2">
          <div className="flex flex-col">
            <div className="flex justify-end">
              <div className="bg-white flex items-center text-center shadow-lg rounded-xl w-[191.09px] h-[87.12px]">
                <p className="text-[17.57px] font-bold leading-[21.96px]">
                  Make <span className="text-astravest-blue">international </span>
                  <span className="text-astravest-blue">transfers</span> swiftly
                </p>
              </div>
            </div>
            <div className="relative bottom-10 -mb-10">
              <img src={SwapMockup} alt="payments mockup" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
