export const maskEmail = (email: string) => {
  const [username, domain] = email.split('@');
  const usernameLength = username.length;

  const maskedUsername = username.charAt(0) + '*'.repeat(5) + username.charAt(usernameLength - 1);

  const maskedDomain = domain.charAt(0) + '*'.repeat(5);

  const maskedEmail = maskedUsername + '@' + maskedDomain;

  return maskedEmail;
};
