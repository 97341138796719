import { Drawer, notification } from 'antd';
import leftIcon from 'assets/Arrow - Left b.svg';
import usdIcon from 'assets/Group 1171275979.svg';
import ngnIcon from 'assets/nga.svg';
import { FC, useEffect, useState } from 'react';
import { useProcessTransactionMutation } from 'services/agentServices/agent';

interface DataType {
  source_amount: string;
  total_amount: string;
  description: string;
  key: React.Key;
  date: string;
  type: string;
  status: string;
  amount: string;
  fees: string;
  purpose: string;
  beneficiary: {
    currency: string;
    provider: string;
    bank_code: string;
    bank_name: string;
  };
}

interface TransactionModalProps {
  openModal: boolean;
  close: () => void;
  transaction: DataType | null;
}

interface InfoRowProps {
  label: string;
  value: string;
  imgSrc?: string;
  statusStyles?: CustomCSSProperties;
}
interface CustomCSSProperties extends React.CSSProperties {
  dotColor?: string;
}

interface ErrorResponse {
  data: {
    message: string;
  };
}

const TransactionModal: FC<TransactionModalProps> = ({ openModal, close, transaction }) => {
  const [processTransaction, { isLoading }] = useProcessTransactionMutation();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (openModal) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setLoading(true);
    }
  }, [openModal]);

  const getStatusStyle = (status: string): CustomCSSProperties => {
    switch (status) {
      case 'Completed':
        return { backgroundColor: '#ECFDF3', color: '#037847', dotColor: '#037847', width: '60%' };
      case 'Processing':
        return {
          backgroundColor: '#FBB0401A',
          color: '#FBB040',
          dotColor: '#FBB040',
          width: '60%',
        };
      case 'Initiated':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '50%',
        };
      case 'Failed':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '40%',
        };
      default:
        return {};
    }
  };

  const InfoRow: FC<InfoRowProps> = ({ label, value, imgSrc, statusStyles }) => (
    <div className="flex flex-col gap-2 w-[50%]">
      <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">{label}</p>
      <div
        className="flex items-center gap-2"
        style={
          statusStyles && {
            padding: '0px 0 0 10px',
            borderRadius: '16px',
            display: 'flex',
            alignItems: 'center',
            height: '22px',
            fontSize: '12px',
            backgroundColor: statusStyles.backgroundColor,
            color: statusStyles.color,
            width: statusStyles.width,
          }
        }
      >
        {statusStyles?.dotColor && (
          <div
            style={{
              width: '6px',
              height: '6px',
              borderRadius: '50%',
              backgroundColor: statusStyles.dotColor,
            }}
          ></div>
        )}
        {imgSrc && <img src={imgSrc} alt={label} className="h-[15.94px] w-[15.94px]" />}
        <h1
          className="text-[14px] leading-[20px] font-[700]"
          style={statusStyles && { color: statusStyles.color }}
        >
          {value}
        </h1>
      </div>
    </div>
  );

  const getCurrencyFromAmount = (amount: string): 'NGN' | 'USD' => {
    if (amount.includes('NGN') || amount.includes('₦')) {
      return 'NGN';
    } else if (amount.includes('USD') || amount.includes('$')) {
      return 'USD';
    } else {
      return 'NGN';
    }
  };

  const getIconForCurrency = (currency: 'NGN' | 'USD') => {
    if (currency === 'NGN') {
      return ngnIcon;
    } else if (currency === 'USD') {
      return usdIcon;
    }
    return undefined;
  };

  const determineCurrency = transaction ? getCurrencyFromAmount(transaction.amount) : 'USD';

  const handleProcessTransaction = async () => {
    if (transaction) {
      try {
        await processTransaction({ transactionsId: transaction.key.toString() }).unwrap();
        close();
        notification.success({
          message: 'Success',
          description: 'transaction processing',
        });
      } catch (error: unknown) {
        const err = error as ErrorResponse;
        if (
          err &&
          typeof err === 'object' &&
          'data' in err &&
          typeof err.data === 'object' &&
          'message' in err.data
        ) {
          notification.error({
            message: 'Error',
            description: err.data.message,
          });
        }
      }
    }
  };

  return (
    <Drawer
      closable
      destroyOnClose
      title={<p>Transaction Details</p>}
      open={openModal}
      loading={loading}
      onClose={() => close()}
      width={500}
    >
      <div className="flex flex-col gap-3">
        {transaction && (
          <div className="border-[0.5px] border-[#EAECF0] lg:w-[447px] rounded-[10px] flex flex-col gap-[25px] p-[20px] lg:p-[30px]">
            <div className="flex justify-between">
              <InfoRow label="Purpose" value={transaction.purpose} />
              <InfoRow
                label="Transaction Status"
                value={transaction.status}
                statusStyles={getStatusStyle(transaction.status)}
              />
            </div>
            <div className="flex justify-between">
              <InfoRow
                label="Source Amount"
                value={transaction.source_amount}
                imgSrc={getIconForCurrency(determineCurrency)}
              />
              <InfoRow
                label="Total Amount"
                value={transaction.total_amount}
                imgSrc={getIconForCurrency(determineCurrency)}
              />
            </div>
            <div className="flex justify-between">
              <InfoRow
                label="Amount"
                value={transaction.amount}
                imgSrc={getIconForCurrency(determineCurrency)}
              />
              <InfoRow
                label="Fees"
                imgSrc={getIconForCurrency(determineCurrency)}
                value={transaction.fees}
              />
            </div>
            <div className="flex justify-between">
              <InfoRow label="Date of Transaction" value={transaction.date} />
              <InfoRow label="Transaction Description" value={transaction.description} />
            </div>

            <div className="flex justify-between">
              <InfoRow label="Currency" value={transaction.beneficiary.currency} />
              <InfoRow label="Provider" value={transaction.beneficiary.provider} />
            </div>
            <div className="flex justify-between">
              <InfoRow label="Bank Code" value={transaction.beneficiary.bank_code} />
              <InfoRow label="Bank Name" value={transaction.beneficiary.bank_name} />
            </div>
          </div>
        )}

        {transaction && transaction.status !== 'Completed' && (
          <div className="flex items-center mt-[30px]">
            <button
              onClick={handleProcessTransaction}
              className="flex justify-center gap-2 items-center rounded-[10px] bg-[#1174ED] px-[7px] py-[20px] h-[45px] w-[100%] font-[700] text-[14px] text-[#ffff] leading-[18.86px]"
              disabled={isLoading}
            >
              {isLoading ? 'Processing...' : 'Process Transaction'}
            </button>
          </div>
        )}

        <div className="mt-[30px]">
          <button
            onClick={close}
            className="flex justify-center gap-2 items-center text-[#1174ED] text-[16px] leading-[22.4px] font-[700]"
          >
            <img src={leftIcon} alt="Back" />
            Back
          </button>
        </div>
      </div>
    </Drawer>
  );
};

export default TransactionModal;
