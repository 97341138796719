import cancelledImage from 'assets/cancel.jpg';
import sucessImage from 'assets/file upload.svg';
import processingImage from 'assets/process.png';
import unknownImage from 'assets/unknown.jfif';
import { Link, useLocation } from 'react-router-dom';
import { ACCOUNT_ROUTE } from 'routes/path';

const Payment = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status');
  const txRef = queryParams.get('tx_ref');

  const getStatusMessage = () => {
    if (status === 'successful') {
      return 'Your account has been funded successfully!';
    } else if (status === 'cancelled') {
      return 'Your payment was cancelled.';
    } else if (status === 'processing') {
      return 'Your payment is processing.';
    } else if (status === 'failed') {
      return 'Your payment failed. Please try again.';
    } else {
      return 'Payment status unknown. Please check your transaction history.';
    }
  };

  const getStatusImage = () => {
    switch (status) {
      case 'successful':
        return sucessImage;
      case 'cancelled':
        return cancelledImage;
      case 'processing':
        return processingImage;
      default:
        return unknownImage;
    }
  };

  return (
    <div className="bg-[url('/src/assets/bg.svg')] bg-[#fff] bg-no-repeat bg-cover bg-center h-[100vh] flex justify-center items-center">
      <div className="bg-[#F9F9FB] rounded-2xl w-[400px] h-[264px] flex justify-center items-center">
        <div className="flex flex-col items-center gap-3 text-center">
          <div>
            <img
              src={getStatusImage()}
              alt="Payment Status"
              className="w-[56px] h-[56px] rounded-[56px]"
            />
          </div>
          <div className="text-[14px] leading-[21px]">
            <h1 className="text-[#1D2739] font-bold">{getStatusMessage()}</h1>
            {txRef && <p className="text-[#98A2B3] font-normal">Transaction Reference: {txRef}</p>}
          </div>
          <Link to={ACCOUNT_ROUTE}>
            <button className="bg-[#1174ED] h-[28px] w-[148px] rounded-md text-[12.59px] leading-[18.89px] font-semibold text-[#F9F9FB]">
              Done
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Payment;
