import { Spin, Table, TableColumnsType } from 'antd';
import arrowIcon from 'assets/Arrow - Right 2.svg';
import debitIcon from 'assets/Group 7.svg';
import receiveIcon from 'assets/Group 107.svg';
import refreshIcon from 'assets/ic_round-refresh.svg';
import searchIcon from 'assets/search.svg';
import useTableScroll from 'components/useTableScroll';
import { useEffect, useState } from 'react';
import { useGetTransactionMutation } from 'services/Transaction';
import { useAppDispatch } from 'store';
import { setTransaction } from 'store/Transaction';
import { DataType } from 'types/Transaction';

const AccountTable = () => {
  const [getTransaction] = useGetTransactionMutation();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isRotated, setIsRotated] = useState(false);
  const scrollX = useTableScroll();

  const handleRefresh = () => {
    setIsRotated(!isRotated);
    setLoading(true);
    getTransaction({})
      .unwrap()
      .then((response) => {
        const transactions = response.data.map(
          (transaction: {
            id: string;
            created_at: string | number | Date;
            reference: string;
            type: string;
            status: string;
            amount: {
              value: string;
              currency: string;
            };
            description: string;
            fees: { value: string };
          }) => {
            const formatTo12Digit = (value: string) => {
              return value.slice(0, 12).padEnd(12, '0');
            };

            const formatAmount = (value: string) => {
              return new Intl.NumberFormat('en-US').format(parseFloat(value));
            };

            return {
              key: transaction.id,
              date: new Date(transaction.created_at).toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              }),
              transaction_id: formatTo12Digit(transaction.reference),
              type: transaction.type,
              status: transaction.status,
              amount: `${transaction.amount.currency} ${formatAmount(transaction.amount.value)}`,
              reference: formatTo12Digit(transaction.reference),
              description: transaction.description,
              fees: transaction.fees.value,
            };
          }
        );
        dispatch(setTransaction(transactions));
        setData(transactions);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      item.type.toLowerCase().includes(searchText.toLowerCase()) ||
      item.status.toLowerCase().includes(searchText.toLowerCase())
  );

  const [showAll, setShowAll] = useState(false);
  const rowsToShow = showAll ? data.length : 10;

  const toggleView = () => {
    setShowAll(!showAll);
  };

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Completed':
        return { backgroundColor: '#ECFDF3', color: '#037847', dotColor: '#037847', width: '63%' };
      case 'Processing':
        return {
          backgroundColor: '#FBB0401A',
          color: '#FBB040',
          dotColor: '#FBB040',
          width: '60%',
        };
      case 'Initiated':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '50%',
        };
      case 'Failed':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '50%',
        };
      default:
        return {};
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text: string, record: DataType) => {
        const icon =
          record.status === 'Initiated' || record.status === 'Failed' ? debitIcon : receiveIcon;
        return (
          <div className="flex items-center gap-2">
            <img src={icon} alt={icon} className="w-[18.9px] h-[18.9px]" />
            {text}
          </div>
        );
      },
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: string) => {
        const { backgroundColor, color, dotColor, width } = getStatusStyle(text);
        return (
          <div
            style={{
              backgroundColor,
              color,
              padding: '2px 8px 2px 6px',
              borderRadius: '16px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: width,
              height: '22px',
            }}
          >
            <div
              style={{
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                backgroundColor: dotColor,
                marginRight: '8px',
              }}
            ></div>
            {text}
          </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
  ];

  return (
    <div>
      <div className="flex flex-col gap-3 border-[0.5px] border-[#EAECF0] rounded-[10px]">
        <div className="flex justify-between border-b border-b-[#EAECF0] p-[15px]">
          <h1 className="font-[900] text-[#343434] text-[14px] lg:text-[24px] leading-[40px]">
            Account Transactions
          </h1>
          <div className="input lg:flex items-center hidden">
            <img src={searchIcon} alt={searchIcon} className="relative left-[30px] -ml-[30px]" />
            <input
              type="text"
              value={searchText}
              onChange={handleSearch}
              placeholder="Search by status"
              className="bg-[#F5F5F5] pl-[35px] rounded-[10px] h-[40px] py-[16.21px] w-[300px]"
            />
          </div>
          <button
            onClick={handleRefresh}
            className="md:flex hidden items-center font-[500] text-[14px] leading-[20px]"
          >
            <img
              src={refreshIcon}
              alt={refreshIcon}
              className={`transition-transform duration-500 ease-in-out ${
                isRotated ? 'rotate-360' : ''
              }`}
            />
            <p>Refresh</p>
          </button>
          <button
            onClick={toggleView}
            className="flex items-center gap-2 text-[#1174ED] font-[700] text-[10px] md:text-[14px] leading-[18.9px]"
          >
            {showAll ? 'View Less' : 'View All'}
            <img src={arrowIcon} alt={arrowIcon} />
          </button>
        </div>

        {loading ? (
          <div className="flex justify-center items-center" style={{ minHeight: '150px' }}>
            <Spin size="large" />
          </div>
        ) : (
          <div className="flex flex-col w-full overflow-auto">
            <Table
              columns={columns}
              dataSource={filteredData.slice(0, rowsToShow)}
              pagination={false}
              size="middle"
              className="table"
              id="table"
              scroll={{
                x: scrollX,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountTable;
