import logoIcon from 'assets/astrabiz-logo.png';
import DotIcon from 'assets/Dot';
import HomeIcon from 'assets/HomeIcon';
import FiveIcon from 'assets/StepFive';
import FourIcon from 'assets/StepFour';
import OneIcon from 'assets/StepOne';
import ThreeIcon from 'assets/StepThree';
import TwoIcon from 'assets/StepTwo';
import { FC, useEffect, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import 'styles/dashboard.css';

interface NavItemProps {
  to: string | string[];
  icon: React.ReactNode;
  label: string;
}

const generateClassName = (label: string) => {
  let className = '';
  if (
    label === 'Business Questionnaire' ||
    label === 'Business Documents' ||
    label === 'Shareholder Information' ||
    label === 'Shareholder Verification'
  ) {
    className = 'side';
  } else if (label === 'Dashboard') {
    className = 'home';
  }
  return className;
};

const NavItem: FC<NavItemProps> = ({ to, icon, label }) => {
  const { pathname } = useLocation();
  const isActive = Array.isArray(to) ? to.includes(pathname) : pathname === to;
  const className = generateClassName(label);

  if (label === 'Shareholder Verification') {
    return (
      <div
        className={`flex gap-[10px] p-[16px] ${
          isActive
            ? 'kyb text-[#1174ED] xl:font-[700] font-[500] flex gap-[10px] p-[16px]'
            : 'text-[#333333]'
        } font-[500] text-[16px] leading-[21.6px] ${className}`}
      >
        {icon}
        <p className="pt-[4px]">{label}</p>
      </div>
    );
  }

  return (
    <>
      {isActive ? (
        <div
          className={`kyb text-[#1174ED] xl:font-[700] font-[500] flex gap-[10px] p-[16px] ${className} ${
            label === 'Business Details' ? 'jus' : ''
          } ${label === 'Business Address' ? 'just1' : ''}`}
        >
          {icon}
          <p className="pt-[4px]">{label}</p>
        </div>
      ) : (
        <NavLink to={Array.isArray(to) ? to[0] : to}>
          <div
            className={`flex gap-[10px] p-[16px] cursor-pointer text-[#333333] font-[500] text-[16px] leading-[21.6px] ${className} ${
              label === 'Business Details' ? 'just' : ''
            } ${label === 'Business Address' ? 'just1' : ''}`}
          >
            {icon}
            <p className="pt-[4px]">{label}</p>
          </div>
        </NavLink>
      )}
    </>
  );
};

interface KycSideBarProps {
  children: React.ReactNode;
}

const KycSideBar: FC<KycSideBarProps> = ({ children }) => {
  const KycSideBarItems = useMemo(
    () => [
      { to: `/`, icon: <HomeIcon />, label: 'Dashboard' },
      { to: [`/detail`, `/address`], icon: <OneIcon />, label: 'Business Information' },
      { to: `/detail`, icon: <DotIcon />, label: 'Business Details' },
      { to: `/address`, icon: <DotIcon />, label: 'Business Address' },
      { to: `/questionnaire`, icon: <TwoIcon />, label: 'Business Questionnaire' },
      { to: `/documents`, icon: <ThreeIcon />, label: 'Business Documents' },
      { to: `/information`, icon: <FourIcon />, label: 'Shareholder Information' },
      { to: `/verification`, icon: <FiveIcon />, label: 'Shareholder Verification' },
    ],
    []
  );

  useEffect(() => {
    const selectedTab = KycSideBarItems.find((item) =>
      Array.isArray(item.to)
        ? item.to.includes(window.location.pathname)
        : item.to === window.location.pathname
    );

    if (selectedTab) {
      document.title = `Astra | ${selectedTab.label}`;
    }
  }, [KycSideBarItems]);

  return (
    <div className="flex">
      <div className="w-[23%] h-[100vh] hidden lg:flex flex-col gap-[40px] xl:gap-[50px] pt-[40px] items-center bg-[#F5F9FF] sticky top-0 sha">
        <div className="px-[30px] xl:px-0">
          <img src={logoIcon} alt={logoIcon} />
        </div>
        <div className="flex flex-col items-center lg:items-start text-[#09090A] text-[16px] not-italic font-[400] leading-[22.4px]">
          {KycSideBarItems.map((item, index) => (
            <NavItem key={index} to={item.to} icon={item.icon} label={item.label} />
          ))}
        </div>
      </div>
      <div className="bg-[url('/src/assets/bg.svg')] bg-[#fff] bg-no-repeat bg-cover w-full lg:w-[78%] flex pt-[60px] pb-[40px] px-[15px] lg:px-[40px]">
        {children}
      </div>
    </div>
  );
};

export default KycSideBar;
