import { FC } from 'react';

const FiveIcon: FC = () => (
  <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="31" rx="15.5" fill="#D0D0D0" />
    <path
      d="M15.64 21.692C13.336 21.692 11.864 20.252 11.864 17.996H13.016C13.016 19.644 14.024 20.636 15.656 20.636C17.384 20.636 18.504 19.484 18.504 17.788C18.504 16.188 17.464 14.924 15.656 14.924C14.6 14.924 13.64 15.404 13.016 16.092L12.104 15.804L13.48 10.044H19.016V11.1H14.312L13.448 14.7C14.088 14.188 14.92 13.9 15.816 13.9C18.2 13.9 19.672 15.612 19.672 17.756C19.672 20.06 18.04 21.692 15.64 21.692Z"
      fill="white"
    />
  </svg>
);

export default FiveIcon;
