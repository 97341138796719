import { createElement } from 'react';
import {
  ADDRESS_ROUTE,
  DETAIL_ROUTE,
  DOCUMENT_ROUTE,
  INFORMATION_ROUTE,
  QUESTIONAIRE_ROUTE,
  REQUIRED_ROUTE,
  VERIFICATION_ROUTE,
} from 'routes/path';
import { AppRouteConfig } from 'types/navigation';
import Address from 'views/kyb/Address';
import Details from 'views/kyb/Details';
import Document from 'views/kyb/Document';
import Information from 'views/kyb/Information';
import Questionnaire from 'views/kyb/Question';
import Required from 'views/kyb/Required';
import Verification from 'views/kyb/Verification';

const kybRoutes: AppRouteConfig[] = [
  {
    path: REQUIRED_ROUTE,
    element: createElement(Required),
    restricted: true,
  },
  {
    path: DETAIL_ROUTE,
    element: createElement(Details),
    restricted: true,
  },
  {
    path: ADDRESS_ROUTE,
    element: createElement(Address),
    restricted: true,
  },
  {
    path: QUESTIONAIRE_ROUTE,
    element: createElement(Questionnaire),
    restricted: true,
  },
  {
    path: DOCUMENT_ROUTE,
    element: createElement(Document),
    restricted: true,
  },
  {
    path: INFORMATION_ROUTE,
    element: createElement(Information),
    restricted: true,
  },
  {
    path: VERIFICATION_ROUTE,
    element: createElement(Verification),
    restricted: true,
  },
];

export default kybRoutes;
