import { Button, Input, Modal, notification } from 'antd';
import xIcon from 'assets/Close Square.svg';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useAgentWithdrawMutation, useWithdrawMutation } from 'services/wallet';
import * as yup from 'yup';

interface WithdrawModalProps {
  isOpen: boolean;
  handleCancel: () => void;
  isAgent: boolean;
  currency: string;
}

interface ApiError {
  data?: {
    errors?: string[];
  };
}

const validationSchema = yup.object({
  amount: yup
    .number()
    .typeError('Amount must be a number')
    .required('Amount is required')
    .positive('Amount must be greater than zero')
    .min(1, 'Amount must be greater than zero'),
});

const WithdrawModal: FC<WithdrawModalProps> = ({ isOpen, handleCancel, isAgent, currency }) => {
  const [withdraw, { isLoading: isUserLoading }] = useWithdrawMutation();
  const [agentWithdraw, { isLoading: isAgentLoading }] = useAgentWithdrawMutation();

  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const payload = {
          amount: Number(values.amount),
          ...(isAgent && { currency }),
        };
        if (isAgent) {
          await agentWithdraw(payload).unwrap();
          notification.success({
            message: 'Agent Withdrawal Successful',
            description: `Your agent withdrawal request of ${values.amount} is currently being processed`,
          });
        } else {
          await withdraw(payload).unwrap();
          notification.success({
            message: 'Withdrawal Successful',
            description: `Your withdrawal request of ${values.amount} is currently being processed`,
          });
        }
        formik.resetForm();
        handleCancel();
      } catch (error) {
        const typedError = error as ApiError;
        const backendErrors = typedError.data?.errors || [];
        const errorMessage =
          backendErrors.length > 0 ? backendErrors.join(', ') : 'Withdrawal failed';

        notification.error({
          message: 'Withdrawal Failed',
          description: errorMessage,
        });
      }
    },
  });

  const handleModalCancel = () => {
    formik.resetForm();
    formik.setErrors({});
    formik.setTouched({});
    handleCancel();
  };

  return (
    <Modal open={isOpen} onCancel={handleModalCancel}>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-6 text-[15px]">
          <div>
            <div className="flex justify-between">
              <h1 className="font-[700] text-[20px]">{isAgent ? 'Agent Withdraw' : 'Withdraw'}</h1>
              <button onClick={handleModalCancel} type="button">
                <img src={xIcon} alt="Close" className="h-[18px] w-[18px]" />
              </button>
            </div>
            <p className="text-[15px] font-[400]">
              Are you sure you want to withdraw from this action? Once confirmed, this process
              cannot be undone.
            </p>
          </div>
          <div className="flex flex-col gap-4">
            <div>
              <Input
                name="amount"
                placeholder="Amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                status={formik.touched.amount && formik.errors.amount ? 'error' : ''}
              />
              {formik.touched.amount && formik.errors.amount ? (
                <div className="text-red-500">{formik.errors.amount}</div>
              ) : null}
            </div>

            <div className="flex justify-end">
              <Button type="primary" htmlType="submit" loading={isUserLoading || isAgentLoading}>
                {isAgent ? 'Withdraw as Agent' : 'Withdraw'}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default WithdrawModal;
