import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { walletPayOutInput, walletState } from 'types/wallet';

const initialState: walletState = {
  wallet: {},
  convert: {},
  purpose: {},
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWallet: (state, { payload }: PayloadAction<walletState>) => {
      state.wallet = payload;
    },
    setPurpose: (state, { payload }: PayloadAction<walletState>) => {
      state.purpose = payload;
    },
    setConvert: (state, { payload }: PayloadAction<walletState>) => {
      state.purpose = payload;
    },
    setPayOut: (state, { payload }: PayloadAction<walletPayOutInput>) => {
      state.payout = payload;
    },
  },
});

export const walletSelector = (state: RootState) => state.wallet;

export const { setWallet, setConvert, setPurpose, setPayOut } = walletSlice.actions;

export default walletSlice.reducer;
