import Layout from 'components/Layout';
import NavigationBar from 'components/NavigationBar';
import Initialization from 'middlewares/initialize';
import { Fragment } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import routes from 'routes';
import { getCurrentRouteContext } from 'utils/route';

function App() {
  const views = useRoutes(routes);
  const location = useLocation();

  const matched = getCurrentRouteContext(location);

  return (
    <Fragment>
      {matched.showNav && <NavigationBar />}
      <Layout>
        {Initialization({
          children: <div className="App bg-[#FAFCFF] w-full">{views}</div>,
        })}
      </Layout>
    </Fragment>
  );
}

export default App;
