import Logo from 'assets/astrabiz-logo.png';
import Loader from 'components/Loader';
import Toast from 'components/Toast';
import { TOKEN_COOKIE } from 'constants/const';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_ROUTE } from 'routes/path';
import { useLoginMutation, useResendOtpMutation, useVerifyEmailMutation } from 'services/auth';
import cookie from 'services/cookie';
import { useAppDispatch, useAppSelector } from 'store';
import { setEmail } from 'store/auth';
import { maskEmail } from 'utils/maskEmail';

const VerifyEmail = ({ from }: { from: string }) => {
  const [timeLeft, setTimeLeft] = useState(120);

  const [toastMessage, setToastMessage] = useState('');
  const [status, setStatus] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [otp, setOtp] = useState('');

  const { email, password } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const handleShowToast = (message: string) => {
    setShowToast(true);
    setToastMessage(message);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const navigate = useNavigate();

  const [resendOtp] = useResendOtpMutation();
  const [verifyEmail, { isLoading: isLoadingVerifyEmail }] = useVerifyEmailMutation();
  const [login] = useLoginMutation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    if (timeLeft < 1) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    document.title = 'AstraBiz | VerifyEmail';
  }, []);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const handleResetOtp = (data: { email: string }) => {
    resendOtp(data)
      .unwrap()
      .then((payload) => {
        if (payload) {
          handleShowToast(`Another OTP has been sent to your email: ${maskEmail(email)}`);
          setStatus(1);
        }
      })
      .catch(() => {
        handleShowToast('error sending otp, please try again later');
        setStatus(0);
      });
  };

  const handleVerifyEmail = (data: { email: string; otp_code: string }) => {
    verifyEmail(data)
      .unwrap()
      .then((payload) => {
        if (payload === null) {
          login({ email: email, password: password })
            .unwrap()
            .then((payload) => {
              dispatch(setEmail(data.email));
              if (payload.data.access_token) {
                cookie.set(TOKEN_COOKIE, {
                  ...payload.data,
                });
                navigate(BASE_ROUTE);
              }
            })
            .catch(() => {
              handleShowToast('error logging in');
              setStatus(0);
            });
        }
      })
      .catch(() => {
        handleShowToast('error verifying email, please try again later');
        setStatus(0);
      });
  };

  return (
    <div className="p-6 mx-auto w-[80%]">
      {showToast && <Toast status={status} message={toastMessage} onClose={handleCloseToast} />}
      <img src={Logo} alt="astravest-logo" />
      <p className="mt-10 text-2xl font-black">
        {from === 'register' ? 'Verify your email' : 'Verify your identity'}
      </p>

      <p className="mt-12 text-base font-medium">
        To protect your account, you will be required to verify your identity with a{' '}
        <span className="font-bold">One-Time password</span> that has been sent to you.
      </p>
      <p className="mt-5 text-base font-medium">
        Enter the 6-digit OTP that was sent to your{' '}
        {/* <span className="font-bold">email address {maskEmail(email)}</span> */}
      </p>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          from === 'register' && handleVerifyEmail({ email: email, otp_code: otp });
        }}
      >
        <div className="mt-5 grid">
          <label htmlFor="BusinessName" className="text-base font-medium">
            OTP code*
          </label>
          <input
            name="BusinessName"
            className="border mt-2 p-3 rounded-xl placeholder:text-sm"
            placeholder="************"
            type="password"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </div>

        {isLoadingVerifyEmail ? (
          <Loader className="mt-8" />
        ) : (
          <button
            type="submit"
            className="mt-8 w-full text-center bg-astravest-blue text-white py-3 rounded-xl text-xl font-bold"
          >
            Continue
          </button>
        )}
        <div className="mt-5 flex space-x-2 items-center">
          <p className="text-sm font-medium">Didn't get the verification code? </p>
          <button
            onClick={(event) => {
              event.preventDefault();
              setTimeLeft(120);
              handleResetOtp({ email });
            }}
            className={`text-sm font-medium ${
              timeLeft >= 1 && 'cursor-not-allowed'
            } font-bold text-astravest-blue`}
            disabled={timeLeft >= 1}
          >
            Resend OTP{' '}
            {timeLeft >= 1
              ? ` in ${
                  minutes > 0 ? minutes + (minutes > 1 ? ' minutes' : ' minute') : ''
                } ${seconds} ${seconds > 1 ? 'seconds' : 'second'}`
              : ''}
          </button>
        </div>
      </form>
    </div>
  );
};

export default VerifyEmail;
