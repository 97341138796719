import { useEffect, useState } from 'react';

const useTableScroll = () => {
  const [scrollX, setScrollX] = useState<undefined | string>(undefined);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 900) {
        setScrollX('max-content');
      } else {
        setScrollX(undefined);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return scrollX;
};

export default useTableScroll;
