import { FC } from 'react';

const OneIcon: FC = () => (
  <svg width="32" height="110" viewBox="0 0 32 110" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="31" rx="15.5" fill="#D0D0D0" />
    <path d="M15.896 11.084H13.56V10.044H17V21.5H15.896V11.084Z" fill="white" />
    <path d="M16.5 110L16.5 31" stroke="#D0D0D0" strokeWidth="2" />
  </svg>
);

export default OneIcon;
