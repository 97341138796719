import { TOKEN_COOKIE } from 'constants/const';
import cookie from 'services/cookie';

export const baseQuery = {
  baseUrl: `${import.meta.env.VITE_API_URL}`,
  prepareHeaders: (headers: Headers) => {
    const token = cookie.get(TOKEN_COOKIE) || null;
    if (token) {
      headers.set('authorization', `Bearer ${token.access_token}`);
    }

    return headers;
  },
};
