import { Drawer, notification, Radio } from 'antd';
import leftIcon from 'assets/Arrow - Left b.svg';
import chatIcon from 'assets/Chat.svg';
import copyIcon from 'assets/copy-svgrepo-com.svg';
import { useFormik } from 'formik';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { FC, useEffect, useState } from 'react';
import { useWalletFundMutation } from 'services/wallet';
import { useAppSelector } from 'store';
import * as Yup from 'yup';

interface AddFundProps {
  openModal: boolean;
  close: () => void;
}

interface ErrorResponse {
  data: {
    message: string;
  };
}

const AddFund: FC<AddFundProps> = ({ openModal, close }) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (openModal) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setLoading(true);
    }
  }, [openModal]);

  const [selectedMethod, setSelectedMethod] = useState<'bank' | 'FlutterWave'>('bank');
  const [walletFund, { isLoading }] = useWalletFundMutation();
  const { user } = useAppSelector((state) => state.auth);
  const accountNumber = '1304266761';
  const handleCopy = useCopyToClipboard(accountNumber);

  const handleSupportClick = () => {
    close();
    window.open('https://wa.me/+2347019648995?text=Hello%2C%20I%20need%20support', '_blank');
  };

  const validationSchema = Yup.object({
    amount: Yup.string().matches(/^\d+$/, 'Amount must be a number').required('Amount is required'),
  });

  const formik = useFormik({
    initialValues: { amount: '' },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const payload = {
          amount: Number(values.amount),
          redirect_url: `${import.meta.env.VITE_REDIRECT_URL}`,
        };

        const response = await walletFund(payload).unwrap();

        if (response.data && response.data.link) {
          window.location.href = response.data.link;
        } else {
          notification.error({
            message: 'Error',
            description: 'Payment initialization failed. Invalid response from server.',
          });
        }
      } catch (error: unknown) {
        const err = error as ErrorResponse;
        if (
          err &&
          typeof err === 'object' &&
          'data' in err &&
          typeof err.data === 'object' &&
          'message' in err.data
        ) {
          notification.error({
            message: 'Error',
            description: err.data.message,
          });
        }
      }
    },
  });

  return (
    <Drawer
      closable
      destroyOnClose
      title={<p>Add funds to your NGN Account</p>}
      placement="right"
      open={openModal}
      loading={loading}
      onClose={() => close()}
      width={700}
    >
      <form onSubmit={formik.handleSubmit} className="w-full lg:w-[553px]">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2 w-full lg:w-[553px] text-[#343434] font-[500] text-[16px] leading-[22.4px]">
              <p>Select Method</p>
              <Radio.Group
                onChange={(e) => setSelectedMethod(e.target.value)}
                value={selectedMethod}
                className="flex lg:w-[553px] font-[700] text-[14px] leading-[20px]"
              >
                <Radio.Button
                  value="bank"
                  className="w-[50%] h-[40px] items-center flex justify-center"
                >
                  Bank Transfer
                </Radio.Button>
                <Radio.Button
                  value="FlutterWave"
                  className="w-[50%] h-[40px] items-center flex justify-center"
                >
                  FlutterWave
                </Radio.Button>
              </Radio.Group>
              <p>
                After making a bank transfer, please send the Proof of Payment (POP) to our support
                team to have your account credited.
              </p>
            </div>

            {selectedMethod === 'bank' && (
              <div className="flex flex-col gap-6 border-[0.5px] border-[#EAECF0] lg:w-[553px] h-[269px] rounded-[10px] p-[20px]">
                <div className="flex justify-between">
                  <h1 className="text-[#343434] font-[900] text-[15px] lg:text-[20px] leading-[40px]">
                    Bank Details
                  </h1>
                  <button className="flex justify-center items-center text-[#8894EC] font-[700] text-[12.57px] leading-[18.86px] px-[20px] py-[7px] border border-[#8894EC] bg-[#8894EC33] rounded-[30px] w-[72px] h-[33px]">
                    Local
                  </button>
                </div>
                <div className="flex flex-col gap-6">
                  <div className="flex w-[100%]">
                    <div className="flex flex-col gap-2 w-[40%]">
                      <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">
                        Account Name
                      </p>
                      <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
                        ADASTRA TECH LIMITED
                      </h1>
                    </div>
                    <div className="flex flex-col gap-2 w-[60%]">
                      <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">
                        Account Number
                      </p>
                      <div className="flex items-center gap-2">
                        <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
                          {accountNumber}
                        </h1>
                        <button onClick={handleCopy}>
                          <img src={copyIcon} alt={copyIcon} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-[100%]">
                    <div className="flex flex-col gap-2 w-[40%]">
                      <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">
                        Bank Name
                      </p>
                      <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
                        Providus Bank
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {selectedMethod === 'FlutterWave' && (
              <div className="flex flex-col gap-4 border-[0.5px] border-[#EAECF0] lg:w-[553px] rounded-[10px] p-[20px]">
                <div>
                  <h1 className="text-[#343434] font-[900] text-[16px] lg:text-[20px] leading-[40px]">
                    Add Funds with FlutterWave
                  </h1>
                  <p className="text-[#343434] font-[500] text-[14px] lg:text-[16px] leading-[20px]">
                    You can add funds to your account through FlutterWave’s secure payment gateway.
                  </p>
                </div>
                <div>
                  <p className="text-[#343434] font-[500] text-[14px] lg:text-[16px] leading-[22.4px]">
                    Amount
                  </p>
                  <input
                    className="h-[46px] p-3 w-full lg:w-[70%] border border-[#D0D0D0] rounded-[8px]"
                    placeholder="50000"
                    name="amount"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.amount && formik.errors.amount && (
                    <div className="text-red-500 text-sm">{formik.errors.amount}</div>
                  )}
                </div>
                <button
                  type="submit"
                  className="flex justify-center gap-2 items-center rounded-[10px] bg-[#1174ED] px-[7px] py-[20px] h-[40px] w-[170px] font-[700] text-[14px] text-[#ffff] leading-[18.86px]"
                  disabled={isLoading || !formik.isValid}
                >
                  {isLoading ? 'Processing...' : 'Pay Now'}
                </button>
              </div>
            )}
          </div>

          {!user?.is_bvn_verified && (
            <div className="box flex flex-col gap-3 lg:h-[93px] border-[1px] border-[#1174ED] bg-[#F5F9FF] rounded-[10px] p-[15px]">
              <div className="flex gap-2">
                <div>
                  <div className="h-[21px] w-[21px] bg-[#1174ED] rounded-[6px] font-[700] flex justify-center items-center text-[#fff] relative top-4">
                    i
                  </div>
                </div>
                <div>
                  <h1 className="text-[#27303A] font-[700] text-[16px] leading-[22.4px]">
                    Activate your Account
                  </h1>
                  <div className="text-[#666666] font-[500] text-[13px] leading-[19px] flex flex-col gap-1">
                    <p>
                      Your account is approved to receive payments from your company's other bank
                      accounts only. Payments from individuals or other companies will be rejected
                      and returned to the sender.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-between items-center mt-[30px]">
            <button
              onClick={close}
              className="flex justify-center gap-2 items-center text-[#1174ED] text-[16px] leading-[22.4px] font-[700]"
            >
              <img src={leftIcon} alt={leftIcon} />
              Back
            </button>
            <button
              onClick={handleSupportClick}
              className="flex justify-center gap-2 items-center rounded-[10px] bg-[#1174ED] px-[7px] py-[20px] h-[40px] w-[170px] font-[700] text-[12px] lg:text-[14px] text-[#ffff] leading-[18.86px]"
            >
              <img src={chatIcon} alt={chatIcon} />
              Talk to Support
            </button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

export default AddFund;
