import { notification } from 'antd';
import { useCallback } from 'react';

const useCopyToClipboard = (textToCopy: string) => {
  const handleCopy = useCallback(() => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'Account number copied to clipboard!',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Text failed to copy',
        });
      });
  }, [textToCopy]);

  return handleCopy;
};

export default useCopyToClipboard;
