import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { agentState } from 'types/agent';

const initialState: agentState = {
  agent: {},
};

const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    setBeneficiary: (state, { payload }: PayloadAction<agentState>) => {
      state.agent = payload;
    },
  },
});

export const walletSelector = (state: RootState) => state.agent;

export const { setBeneficiary } = agentSlice.actions;

export default agentSlice.reducer;
