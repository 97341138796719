import { Button, Input, Modal, notification } from 'antd';
import rightArrowIcon from 'assets/Arrow1 - Left.svg';
import xIcon from 'assets/Close Square.svg';
import { ErrorMessage, Field, FieldProps, Form as FormikForm, Formik } from 'formik';
import { FC } from 'react';
import { useAddShareHolderMutation } from 'services/kyb';
import * as Yup from 'yup';

interface MyModalProps {
  isOpen: boolean;
  handleCancel: () => void;
  getUserKyb: () => void;
}

interface ErrorResponse {
  data: {
    message: string;
  };
}

const MyModal: FC<MyModalProps> = ({ isOpen, handleCancel, getUserKyb }) => {
  const [addShareHolder] = useAddShareHolderMutation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    legalFirstName: Yup.string().required('First Name is required'),
    legalLastName: Yup.string().required('Last Name is required'),
  });

  const handleSubmit = async (
    values: {
      email: string;
      legalFirstName: string;
      legalLastName: string;
    },
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      const payload = {
        email: values.email,
        first_name: values.legalFirstName,
        last_name: values.legalLastName,
      };
      const response = await addShareHolder(payload).unwrap();
      notification.success({
        message: 'Success',
        description: 'Shareholder added successfully',
      });
      resetForm();
      handleCancel();
      getUserKyb();
      response();
    } catch (error: unknown) {
      const err = error as ErrorResponse;
      if (
        err &&
        typeof err === 'object' &&
        'data' in err &&
        typeof err.data === 'object' &&
        'message' in err.data
      ) {
        notification.error({
          message: 'Error',
          description: err.data.message,
        });
      }
    }
  };

  return (
    <Modal open={isOpen} onCancel={handleCancel}>
      <div
        style={{ fontFamily: 'Figtree' }}
        className="flex flex-col gap-[10px] text-[16px] lg:text-[18px] not-italic font-[400] leading-[140%] tracking-[0.16px] lg:tracking-[0.18px]"
      >
        <div className="flex justify-end">
          <button onClick={handleCancel}>
            <img src={xIcon} alt={xIcon} className="h-[18px] w-[18px]" />
          </button>
        </div>
        <Formik
          initialValues={{
            email: '',
            legalFirstName: '',
            legalLastName: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ setFieldValue }) => (
            <FormikForm className="text-[#323336] flex flex-col gap-4 font-[500] text-[16px] leading-[22.4px]">
              <div className="flex flex-col gap-1">
                <p>Email</p>
                <Field name="email">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      placeholder="Email"
                      onChange={(e) => setFieldValue('email', e.target.value)}
                    />
                  )}
                </Field>
                <ErrorMessage name="email" component="div" className="text-[red]" />
              </div>
              <div className="flex flex-col gap-1">
                <p>Legal First Name</p>
                <Field name="legalFirstName">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      placeholder="First Name"
                      onChange={(e) => setFieldValue('legalFirstName', e.target.value)}
                    />
                  )}
                </Field>
                <ErrorMessage name="legalFirstName" component="div" className="text-[red]" />
              </div>
              <div className="flex flex-col gap-1">
                <p>Legal Last Name</p>
                <Field name="legalLastName">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      placeholder="Last Name"
                      onChange={(e) => setFieldValue('legalLastName', e.target.value)}
                    />
                  )}
                </Field>
                <ErrorMessage name="legalLastName" component="div" className="text-[red]" />
              </div>
              <div className="flex justify-end gap-[32px] pt-[20px]">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="flex justify-center items-center gap-2 font-[700] text-[14px] leading-[18.86px] py-[7px] px-[20px] h-[40px] bg-[#1174ED] text-[#fff] rounded-[10px]"
                >
                  Submit
                  <img src={rightArrowIcon} alt={rightArrowIcon} />
                </Button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default MyModal;
