import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TOKEN_COOKIE } from 'constants/const';
import cookie from 'services/cookie';
import { LoginInput, RegisterInput } from 'types/auth';
import { baseQuery } from 'utils/baseQuery';

const customBaseQuery = fetchBaseQuery({
  baseUrl: baseQuery.baseUrl,
  prepareHeaders: (headers) => {
    return baseQuery.prepareHeaders(headers);
  },
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object
) => {
  const result = await customBaseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    clearCookiesAndLogout();
  }

  return result;
};

const clearCookiesAndLogout = () => {
  document.cookie.split('; ').forEach((cookie) => {
    document.cookie = `${cookie.split('=')[0]}=;expires=${new Date(0).toUTCString()};path=/`;
  });

  window.location.href = '/login';
};

export const authApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: 'authApi',
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (payload: LoginInput) => ({
        url: '/auth/login',
        method: 'POST',
        body: payload,
      }),
    }),
    agentLogin: builder.mutation({
      query: (payload: LoginInput) => ({
        url: '/agent/auth/login',
        method: 'POST',
        body: payload,
      }),
    }),
    register: builder.mutation({
      query: (payload: RegisterInput) => ({
        url: '/auth/signup',
        method: 'POST',
        body: payload,
      }),
    }),
    resendOtp: builder.mutation({
      query: (payload: { email: string }) => ({
        url: '/auth/resend-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    agentResendOtp: builder.mutation({
      query: (payload: { email: string }) => ({
        url: '/agent/auth/resend-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyEmail: builder.mutation({
      query: (payload: { email: string; otp_code: string }) => ({
        url: '/auth/verify-email',
        method: 'POST',
        body: payload,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (payload: { email: string }) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: payload,
      }),
    }),
    agentForgotPassword: builder.mutation({
      query: (payload: { email: string }) => ({
        url: '/agent/auth/forgot-password',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (payload: { email: string; otp_code: string }) => ({
        url: '/auth/verify-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    resetPassword: builder.mutation({
      query: (payload: { email: string; password: string }) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: payload,
      }),
    }),
    agentResetPassword: builder.mutation({
      query: (payload: { email: string; password: string; otp_code: string }) => ({
        url: '/agent/auth/reset-password',
        method: 'POST',
        body: payload,
      }),
    }),
    getUser: builder.mutation({
      query: () => ({
        url: '/auth/user',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useAgentLoginMutation,
  useRegisterMutation,
  useResendOtpMutation,
  useAgentResendOtpMutation,
  useVerifyEmailMutation,
  useForgotPasswordMutation,
  useAgentForgotPasswordMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
  useAgentResetPasswordMutation,
  useGetUserMutation,
} = authApi;

export function getAuthToken(): { access_token: string } | null {
  return cookie.get(TOKEN_COOKIE);
}
