import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { beneficiariesState } from 'types/beneficiaries';

const initialState: beneficiariesState = {
  beneficiary: {},
};

const beneficiarySlice = createSlice({
  name: 'beneficiary',
  initialState,
  reducers: {
    setBeneficiary: (state, { payload }: PayloadAction<beneficiariesState>) => {
      state.beneficiary = payload;
    },
  },
});

export const walletSelector = (state: RootState) => state.beneficiary;

export const { setBeneficiary } = beneficiarySlice.actions;

export default beneficiarySlice.reducer;
