import { createElement, lazy } from 'react';
import { ERROR_404_ROUTE } from 'routes/path';
import { AppRouteConfig } from 'types/navigation';
const NotFound = lazy(() => import('views/error/NotFound'));

const errorRoutes: AppRouteConfig[] = [
  {
    path: ERROR_404_ROUTE,
    element: createElement(NotFound),
    isolated: true,
    isError: true,
  },
];
export default errorRoutes;
