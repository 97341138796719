import { FC } from 'react';

const FourIcon: FC = () => (
  <svg width="32" height="59" viewBox="0 0 32 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="31" rx="15.5" fill="#D0D0D0" />
    <path
      d="M19.62 19.068H18.276V21.5H17.156V19.068H11.14V18.044L16.74 10.044H18.276V18.044H19.62V19.068ZM12.388 18.044H17.156V11.228L12.388 18.044Z"
      fill="white"
    />
    <path d="M16.5 59V31" stroke="#D0D0D0" strokeWidth="2" />
  </svg>
);

export default FourIcon;
