import { Form, notification, Spin, Upload } from 'antd';
import leftArrowIcon from 'assets/Arrow - Left b.svg';
import rightArrowIcon from 'assets/Arrow1 - Left.svg';
import uploadIcon from 'assets/file upload states.svg';
import KycSideBar from 'components/KybSideBar';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_ROUTE, INFORMATION_ROUTE, QUESTIONAIRE_ROUTE } from 'routes/path';
import { useGetBusinessMutation } from 'services/business';
import { useGetKYBMutation, useKybUploadMutation } from 'services/kyb';
import { useAppDispatch, useAppSelector } from 'store';
import { setBusiness } from 'store/business';
import { setKYB } from 'store/kyb';

const Document = () => {
  const [kybUpload] = useKybUploadMutation();
  const { kyb } = useAppSelector((state) => state.kyb);
  const [getBusiness] = useGetBusinessMutation();
  const [getKYB] = useGetKYBMutation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const navigate = useNavigate();

  const compulsoryDocuments = useMemo(
    () => ['CERTIFICATE_OF_INCORPORATION', 'SHAREHOLDING_STRUCTURE', 'PROOF_OF_BUSINESS_ADDRESS'],
    []
  );

  useEffect(() => {
    getBusiness({})
      .unwrap()
      .then((response) => {
        dispatch(setBusiness(response.data));
        getUserKyb();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getBusiness, getKYB]);

  const getUserKyb = () => {
    getKYB({})
      .unwrap()
      .then((response) => {
        dispatch(setKYB(response.data));
      });
  };

  const handleUpload = async (file: string | Blob, type: string) => {
    const formData = new FormData();
    formData.append('type', type);
    formData.append('document', file);

    setLoading((prev) => ({ ...prev, [type]: true }));
    try {
      await kybUpload(formData);
      notification.success({
        message: `${type} uploaded successfully`,
      });
      getUserKyb();
    } catch (error) {
      const errorMsg = (error as Error).message || 'An error occurred during the upload.';
      notification.error({
        message: `${type} upload failed`,
        description: errorMsg,
      });
    } finally {
      setLoading((prev) => ({ ...prev, [type]: false }));
    }
  };

  const uploadProps = (type: string) => ({
    beforeUpload: (file: File) => {
      handleUpload(file, type);
      return false;
    },
  });

  const documentTypes = [
    {
      label: 'Certificate of Incorporation - Operating Agreement',
      type: 'CERTIFICATE_OF_INCORPORATION',
      compulsory: true,
    },
    {
      label: 'Shareholders (Co7 or supporting)',
      type: 'SHAREHOLDING_STRUCTURE',
      compulsory: false,
    },
    {
      label: 'Proof of Business Address',
      type: 'PROOF_OF_BUSINESS_ADDRESS',
      compulsory: true,
    },
    {
      label: 'Supporting Documents',
      type: 'SUPPORTING_DOCUMENTS',
      compulsory: false,
    },
  ];

  const getDocumentStatus = (type: string) => {
    if (kyb && kyb.kyb_docs) {
      const doc = kyb.kyb_docs.find((d: { type: string }) => d.type === type);
      if (doc) {
        return {
          status: 'Available',
          type: doc.type,
        };
      }
    }
    return {
      status: 'Unavailable',
      type: null,
    };
  };

  const areCompulsoryDocumentsUploaded = () => {
    const missingDocuments = compulsoryDocuments.filter((type) => {
      const { status } = getDocumentStatus(type);
      return status !== 'Available';
    });

    if (missingDocuments.length > 0) {
      notification.warning({
        message: 'Missing Documents',
        description: `Please upload the following compulsory documents before proceeding: ${missingDocuments.join(
          ', '
        )}`,
      });
      return false;
    }
    return true;
  };

  const handleNextClick = () => {
    if (areCompulsoryDocumentsUploaded()) {
      navigate(INFORMATION_ROUTE);
    }
  };

  return (
    <KycSideBar>
      <div className="bg-[#fff] rounded-[10px] py-[15px] sha lg:py-[60px] px-[20px] lg:px-[40px] lg:w-[1030px] flex flex-col justify-between">
        <Form>
          <div className="flex flex-col gap-5 xl:w-[882px]">
            <h1 className="font-[900] text-[20px] lg:text-[28px] leading-[30.61px] text-[#343434]">
              Business Documents
            </h1>
            <div className="flex flex-col">
              <h1 className="text-[#343434] font-[700] leading-[30.61px] text-[18px] lg:text-[24px]">
                Organizational Documents
              </h1>
              <p className="font-[400] text-[16px] leading-[21.6px] mt-3 lg:w-[450px] text-[#000000]">
                Upload the documents used to form or organise your business
              </p>
            </div>

            {documentTypes.map((doc) => {
              const { status, type: fileType } = getDocumentStatus(doc.type);

              return (
                <div
                  key={doc.type}
                  className="lg:h-[94px] w-full xl:w-[882px] border-dashed border-[1.21px] border-[#D0D0D0] bg-[#FFFFFF] flex lg:flex-row flex-col gap-3 justify-between items-center p-[15px] font-[500] rounded-[12.92px]"
                >
                  <div className="xl:w-[439px] md:w-[200px]">
                    <p className="text-[#000000] text-[16px] leading-[23.2px]">
                      {doc.label}
                      {doc.compulsory && <span className="text-[red]">*</span>}
                    </p>
                  </div>
                  <div className="flex flex-col items-center">
                    <p
                      className={`py-[6px] px-[12px] rounded-[10px] text-[14px] leading-[20.3px] relative top- ${
                        status === 'Available'
                          ? 'bg-[#0378471A] text-[#037847]'
                          : 'bg-[#D9534F1A] text-[#D9534F]'
                      }`}
                    >
                      {status}
                    </p>
                    {status === 'Available' && (
                      <p className="text-[14px] leading-[20.3px] text-[#037847] relative top-3">
                        {fileType}
                      </p>
                    )}
                    {status === 'Unavailable'}
                  </div>
                  <div>
                    <Upload {...uploadProps(doc.type)}>
                      <button className="flex gap-1 text-[#1174ED] font-[700] text-[14px] leading-[20.3px] px-[20px] py-[7px] border border-[#1174ED] rounded-[10px]">
                        {loading[doc.type] ? (
                          <Spin size="small" />
                        ) : (
                          <>
                            <img src={uploadIcon} alt={uploadIcon} />
                            Upload
                          </>
                        )}
                      </button>
                    </Upload>
                  </div>
                </div>
              );
            })}
          </div>
        </Form>
        <div className="flex justify-between items-center xl:w-[882px] mt-[50px]">
          <Link to={BASE_ROUTE}>
            <button className="flex items-center gap-1 text-[#1174ED] font-[700] leading-[22.4px] text-[16px]">
              <img src={leftArrowIcon} alt={leftArrowIcon} />
              Save <span className="hidden lg:block">and continue later</span>
            </button>
          </Link>
          <div className="flex items-center gap-2">
            <Link to={QUESTIONAIRE_ROUTE}>
              <button className="flex justify-center items-center gap-2 font-[700] text-[14px] leading-[18.86px] py-[7px] px-[20px] h-[40px] border border-[#1174ED] bg-inherit text-[#1174ED] rounded-[10px]">
                <img src={leftArrowIcon} alt={leftArrowIcon} />
                Back
              </button>
            </Link>
            <button
              onClick={handleNextClick}
              className="flex justify-center items-center gap-2 font-[700] text-[14px] leading-[18.86px] py-[7px] px-[20px] h-[40px] bg-[#1174ED] text-[#FFFFFF] rounded-[10px]"
            >
              Next
              <img src={rightArrowIcon} alt={rightArrowIcon} />
            </button>
          </div>
        </div>
      </div>
    </KycSideBar>
  );
};

export default Document;
