import Failed from 'assets/failed.svg';
import Success from 'assets/success.svg';
import { motion } from 'framer-motion';
import { useState } from 'react';

const Toast = ({
  message,
  onClose,
  status,
}: {
  message: string;
  onClose: () => void;
  status: number;
}) => {
  const [visible, setVisible] = useState(true);

  setTimeout(() => {
    setVisible(false);
    onClose();
  }, 5000);

  if (!visible) {
    return null;
  }

  return (
    <motion.div
      initial={{ x: 1000 }}
      animate={{ opacity: 1, scale: 1, x: 1 }}
      transition={{
        duration: 0.35,
        delay: 0.1,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      className="toast flex space-x-2 border w-max fixed top-20 right-5 text-black bg-white px-8 py-3 rounded-xl"
    >
      <p className="text-sm font-bold my-auto">{message}</p>
      {!status ? (
        <img src={Failed} alt="failed" className="w-8 h-8 my-auto" />
      ) : (
        <img src={Success} alt="success" className="w-8 h-8 my-auto" />
      )}
    </motion.div>
  );
};

export default Toast;
