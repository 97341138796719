import { FC } from 'react';

const TwoIcon: FC = () => (
  <svg width="32" height="59" viewBox="0 0 32 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="31" rx="15.5" fill="#D0D0D0" />
    <path
      d="M19.772 21.5H12.22V20.588L15.884 17.388C17.996 15.548 18.54 14.748 18.54 13.388C18.54 11.836 17.612 10.892 16.092 10.892C14.508 10.892 13.468 12.044 13.42 13.82H12.268C12.332 11.42 13.852 9.836 16.108 9.836C18.268 9.836 19.708 11.228 19.708 13.324C19.708 14.988 18.956 16.092 16.668 18.092L13.996 20.428H19.772V21.5Z"
      fill="white"
    />
    <path d="M16.5 59V31" stroke="#D0D0D0" strokeWidth="2" />
  </svg>
);

export default TwoIcon;
