import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  KybAddShareHolderInput,
  kybInput,
  KybQuestionaireInput,
  KybShareHolderVerifyInput,
  KybState,
  KybUpdateInput,
  KybUploadInput,
} from 'types/kyb';

const initialState: KybState = {
  kybData: {},
  kyb: {},
};

const kybSlice = createSlice({
  name: 'kyb',
  initialState,
  reducers: {
    setKybUpdate: (state, { payload }: PayloadAction<KybUpdateInput>) => {
      state.kyb = payload;
    },
    setKybQuestionaire: (state, { payload }: PayloadAction<KybQuestionaireInput>) => {
      state.kyb = payload;
    },
    setKybUpload: (state, { payload }: PayloadAction<KybUploadInput>) => {
      state.kyb = payload;
    },
    setAddShareHolder: (state, { payload }: PayloadAction<KybAddShareHolderInput>) => {
      state.kyb = payload;
    },
    setShareHolderVerify: (state, { payload }: PayloadAction<KybShareHolderVerifyInput>) => {
      state.kyb = payload;
    },
    setKYB: (state, { payload }: PayloadAction<kybInput>) => {
      state.kyb = payload;
    },
  },
});

export const kybSelector = (state: RootState) => state.kyb;

export const {
  setKybUpdate,
  setKybQuestionaire,
  setKybUpload,
  setAddShareHolder,
  setShareHolderVerify,
  setKYB,
} = kybSlice.actions;

export default kybSlice.reducer;
