import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { Business, BusinessState } from 'types/business';


const initialState: BusinessState = {
  business: {} as Business,
};

const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setBusiness: (state, { payload }: PayloadAction<Business>) => {
      state.business = payload;
    },
  },
});

export const businessSelector = (state: RootState) => state.business;

export const { setBusiness } = businessSlice.actions;

export default businessSlice.reducer;
