import { useEffect, useState } from 'react';
import { useGetAllWalletMutation } from 'services/wallet';
import { useAppDispatch } from 'store';
import { setWallet } from 'store/wallet';

import Account from './Account';

interface AccountType {
  currency: string;
}

interface walletState {
  wallet: AccountType[];
  convert: boolean;
}

const AccountContainer = () => {
  const [getWallet] = useGetAllWalletMutation();
  const dispatch = useAppDispatch();
  const [accounts, setAccounts] = useState<AccountType[]>([]);

  useEffect(() => {
    getWallet({})
      .unwrap()
      .then((response) => {
        const data = response.data as AccountType[];
        setAccounts(data);
        const walletData: walletState = {
          wallet: data,
          convert: true,
        };
        dispatch(setWallet(walletData));
      });

    const interval = setInterval(() => {
      getWallet({});
    }, 8000);

    return () => clearInterval(interval);
  }, [dispatch, getWallet]);

  const accountsDictionary = accounts?.reduce(
    (dict: { [key: string]: AccountType }, account: AccountType) => {
      dict[account.currency] = account;
      return dict;
    },
    {}
  );

  return (
    <div>
      <Account accounts={accountsDictionary} />
    </div>
  );
};

export default AccountContainer;
