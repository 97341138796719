import Logo from 'assets/astrabiz-logo.png';
import AuthBackgroundLines from 'assets/auth-background-lines.svg';
import sucessIcon from 'assets/success1.svg';
import { useEffect } from 'react';

const RegisterSuccess = () => {
  useEffect(() => {
    document.title = 'AstraBiz | RegisterSuccessful';
  }, []);
  return (
    <div className="relative">
      <img
        src={AuthBackgroundLines}
        alt="auth background lines"
        className="max-h-screen w-full object-cover"
      />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="w-4/6 mx-auto flex flex-col gap-16">
          <img src={Logo} alt="astravest-logo" className="mx-auto" />
          <div className="flex flex-col">
            <img src={sucessIcon} alt={sucessIcon} className="mx-auto" />
            <p className="text-center font-black text-3xl">
              Your Astra account has been created successfully!
            </p>
            <p className="text-grey-text text-center mt-4">
              You can start exploring our amazing features and making payments with ease.
            </p>

            <button className="mt-12 text-center w-full bg-astravest-blue text-white text-xl font-bold py-3 rounded-xl">
              Activate my account
            </button>
            <button className="mt-4 text-center w-full border border-astravest-blue text-astravest-blue text-xl font-bold py-3 rounded-xl">
              Take me to dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccess;
