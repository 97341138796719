/* eslint-disable @typescript-eslint/no-explicit-any */
import { Drawer, notification, Radio, RadioChangeEvent, Select } from 'antd';
import ngnIcon from 'assets/flag-for-nigeria-svgrepo-com 1.svg';
import usaIcon from 'assets/Group 1171275979.svg';
import hkdIcon from 'assets/hong-kong-flag-round-circle-icon.svg';
import twoIcon from 'assets/indicatortwo.svg';
import numberOneIcon from 'assets/line 1 - icon + text + line1.svg';
import numberTwoIcon from 'assets/line 2 - icon + text + line.svg';
import cynIcon from 'assets/Mask group.svg';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useAddBeneficiaryMutation } from 'services/beneficiaries';
import * as Yup from 'yup';

interface AddBeneficiariesProps {
  isOpen: boolean;
  modalClose: () => void;
  fetchBeneficiaries: () => void;
}

interface ErrorResponse {
  data: {
    message: string;
  };
}

const AddBeneficiaries: FC<AddBeneficiariesProps> = ({
  isOpen,
  modalClose,
  fetchBeneficiaries,
}) => {
  const [value, setValue] = useState(2);
  const [currency, setCurrency] = useState<string>();
  const [addBeneficiary] = useAddBeneficiaryMutation();
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);
  const [entityType, setEntityType] = useState<string>('individual');

  const validationSchemaNGN = Yup.object().shape({
    accountName: Yup.string()
      .matches(/^[A-Za-z]/, 'Account full name should contain only letters')
      .required('Account full name is required'),
    accountNumber: Yup.string()
      .matches(/^\d+$/, 'Account number must only contain digits')
      .min(6, 'Account number must be at least 6 digits and above')
      .required('Account number is required'),
    country: Yup.string()
      .matches(/^[A-Za-z]/, 'Country name should contain only letters')
      .required('Country name is required'),
    bankName: Yup.string()
      .matches(/^[A-Za-z]/, 'Bank name should contain only letters')
      .required('Bank name is required'),
  });

  const validationSchema = Yup.object().shape({
    bankName: Yup.string()
      .matches(/^[A-Za-z]/, 'Bank name should contain only letters')
      .required('Bank name is required'),
    bankAddress: Yup.string().required('Bank address is required'),
    country: Yup.string().required('Country is required'),
    swiftCode: Yup.string().required('Swift Code is required'),
    beneficiaryName: Yup.string().required('Beneficiary name is required'),
    accountNumber: Yup.string()
      .matches(/^\d+$/, 'Account number must only contain digits')
      .min(6, 'Account number must be at least 6 digits and above')
      .required('Account number is required'),
    accountName: Yup.string()
      .matches(/^[A-Za-z]/, 'Account full name should contain only letters')
      .required('Account full name is required'),
    beneficiaryAddress: Yup.string().required('Beneficiary address is required'),
  });

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    switch (e.target.value) {
      case 1:
        setEntityType('company');
        break;
      case 2:
        setEntityType('individual');
        break;
      case 3:
        setEntityType('company');
        break;
      default:
        setEntityType('individual');
    }
  };

  const handleCurrencyChange = (value: string) => {
    setSelectedCurrency(value);
    setCurrency(value);
  };

  const handleSubmitNGN = async (values: any, { resetForm }: { resetForm: () => void }) => {
    const payload = {
      currency: selectedCurrency,
      entity_type: entityType,
      bank_name: values.bankName,
      country: values.country,
      account_name: values.accountName,
      account_number: values.accountNumber,
      sort_code: values.sortCode,
      bank_address: 'null',
      swift_code: 'null',
      beneficiary_name: 'null',
      beneficiary_address: 'null',
    };
    try {
      await addBeneficiary(payload).unwrap();
      modalClose();
      resetForm();
      fetchBeneficiaries();
      notification.success({
        message: 'Success',
        description: 'You have added a new beneficiary!.',
      });
    } catch (error: any) {
      const err = error as ErrorResponse;
      if (
        err &&
        typeof err === 'object' &&
        'data' in err &&
        typeof err.data === 'object' &&
        'message' in err.data
      ) {
        notification.error({
          message: 'Error',
          description: err.data.message,
        });
      }
    }
  };

  const handleSubmit = async (values: any, { resetForm }: { resetForm: () => void }) => {
    const payload = {
      currency: selectedCurrency,
      entity_type: entityType,
      bank_name: values.bankName,
      bank_address: values.bankAddress,
      country: values.country,
      swift_code: values.swiftCode,
      beneficiary_name: values.beneficiaryName,
      beneficiary_address: values.beneficiaryAddress,
      account_name: values.accountName,
      account_number: values.accountNumber,
      sort_code: values.sortCode,
    };
    try {
      await addBeneficiary(payload).unwrap();
      modalClose();
      resetForm();
      fetchBeneficiaries();
      notification.success({
        message: 'Success',
        description: 'You have added a new beneficiary!',
      });
    } catch (error: any) {
      const err = error as ErrorResponse;
      if (
        err &&
        typeof err === 'object' &&
        'data' in err &&
        typeof err.data === 'object' &&
        'message' in err.data
      ) {
        notification.error({
          message: 'Error',
          description: err.data.message,
        });
      }
    }
  };

  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setLoading(true);
    }
  }, [isOpen]);

  return (
    <Drawer
      closable
      destroyOnClose
      title={<p>Add Beneficiary</p>}
      placement="right"
      open={isOpen}
      loading={loading}
      onClose={() => modalClose()}
      width={700}
    >
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-3">
          <div className="flex gap-2">
            <div className="relative top-2">
              <img src={numberOneIcon} alt="Step 1" />
            </div>
            <div className="flex flex-col w-[100%]">
              <h1 className="font-[900] text-[15px] lg:text-[20px] leading-[40px] text-[#343434]">
                Primary Details
              </h1>
              <div className="flex flex-col gap-4 border-[0.5px] border-[#EAECF0] h-[272px] rounded-[10px] p-[20px]">
                <div className="flex flex-col gap-2">
                  <p className="text-[#343434] font-[500] text-[16px] leading-[22.4px]">
                    Select Currency
                  </p>
                  <Select
                    className="border border-[#EAECF0] rounded-[10px] w-[80%]"
                    placeholder="Select Currency"
                    value={currency}
                    onChange={handleCurrencyChange}
                  >
                    <Select.Option value="NGN">
                      <div className="flex items-center gap-2">
                        <img src={ngnIcon} alt="NGN" className="h-[23.56px] w-[23.56px]" />
                        NGN
                      </div>
                    </Select.Option>
                    <Select.Option value="USD">
                      <div className="flex items-center gap-2">
                        <img src={usaIcon} alt="USD" className="h-[23.56px] w-[23.56px]" />
                        USD
                      </div>
                    </Select.Option>
                    <Select.Option value="CNY">
                      <div className="flex items-center gap-2">
                        <img src={cynIcon} alt="CNY" className="h-[23.56px] w-[23.56px]" />
                        CNY
                      </div>
                    </Select.Option>
                    <Select.Option value="HKD">
                      <div className="flex items-center gap-2">
                        <img src={hkdIcon} alt="CNY" className="h-[23.56px] w-[23.56px]" />
                        HKD
                      </div>
                    </Select.Option>
                  </Select>
                </div>
                <div className="font-[500] text-[14px] leading-[20px] flex flex-col gap-3">
                  <p className="text-[#344054]">Choose Beneficiary Type</p>
                  <Radio.Group
                    onChange={onChange}
                    value={value}
                    className="flex flex-col gap-3 text-[#101828]"
                  >
                    <Radio value={1} className="flex">
                      <div className="flex items-center gap-1">
                        <p>Company</p>
                        <div className="w-[6.67px] h-[6.67px] bg-[#1174ED]"></div>
                      </div>
                    </Radio>
                    <Radio value={2} className="flex">
                      <div className="flex items-center gap-1">
                        <p>Individual</p>
                        <div className="w-[6.67px] h-[6.67px] bg-[#1174ED]"></div>
                      </div>
                    </Radio>
                    <Radio value={3} className="flex">
                      <div className="flex items-center gap-1">
                        <p>My Organization</p>
                        <div className="w-[6.67px] h-[6.67px] bg-[#1174ED]"></div>
                      </div>
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
          </div>

          {selectedCurrency && (
            <div>
              {currency === 'NGN' ? (
                <Formik
                  key="NGN"
                  initialValues={{
                    firstName: '',
                    accountNumber: '',
                    bankName: '',
                    bankCode: '',
                  }}
                  validationSchema={validationSchemaNGN}
                  onSubmit={handleSubmitNGN}
                >
                  {() => (
                    <Form className="flex gap-2">
                      <div className="relative top-2">
                        <img src={numberTwoIcon} alt="Step 2" />
                      </div>
                      <div className="flex flex-col w-[100%]">
                        <h1 className="font-[900] text-[15px] lg:text-[20px] leading-[40px] text-[#343434]">
                          Bank Details
                        </h1>
                        <div className="flex flex-col gap-[25px] border border-[#EAECF0] py-[8px] rounded-[10px] p-[20px]">
                          <p className="pt-[20px] text-[#101828] text-[14px] leading-[20px] font-[500]">
                            Kindly provide the bank details of the beneficiary
                          </p>
                          <div className="flex flex-col w-[100%] text-[#344054] font-[500] text-[14px] leading-[20px]">
                            <p>Account Name*</p>
                            <Field
                              type="text"
                              name="accountName"
                              className="border border-[#EAECF0] rounded-[10px] h-[51px] pl-3 w-[100%]"
                            />
                            <ErrorMessage
                              name="accountName"
                              component="div"
                              className="text-red-500"
                            />
                          </div>
                          <div className="flex flex-col lg:flex-row gap-2">
                            <div className="flex flex-col w-full lg:w-[50%] text-[#344054] font-[500] text-[14px] leading-[20px]">
                              <p>Account Number/IBAN*</p>
                              <Field
                                type="text"
                                name="accountNumber"
                                className="border border-[#EAECF0] rounded-[10px] h-[51px] pl-3 w-[100%]"
                              />
                              <ErrorMessage
                                name="accountNumber"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                            <div className="flex flex-col w-full lg:w-[50%] text-[#344054] font-[500] text-[14px] leading-[20px]">
                              <p>Country*</p>
                              <Field
                                type="text"
                                name="country"
                                className="border border-[#EAECF0] rounded-[10px] h-[51px] pl-3 w-[100%]"
                              />
                              <ErrorMessage
                                name="country"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col lg:flex-row gap-2">
                            <div className="flex flex-col w-full lg:w-[50%] text-[#344054] font-[500] text-[14px] leading-[20px]">
                              <p>Bank Name*</p>
                              <Field
                                type="text"
                                name="bankName"
                                className="border border-[#EAECF0] rounded-[10px] h-[51px] pl-3 w-[100%]"
                              />
                              <ErrorMessage
                                name="bankName"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                            <div className="flex flex-col w-full lg:w-[50%] text-[#344054] font-[500] text-[14px] leading-[20px]">
                              <p>Sort Code (Optional)*</p>
                              <Field
                                type="text"
                                name="sortCode"
                                className="border border-[#EAECF0] rounded-[10px] h-[51px] pl-3 w-[100%]"
                              />
                            </div>
                          </div>
                          <div className="flex justify-end">
                            <button
                              type="submit"
                              className="bg-[#1174ED] text-[#FCFCFD] font-[600] text-[14px] leading-[20px] rounded-[8px] px-[20px] py-[10px]"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : (
                <Formik
                  key={currency}
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    accountNumber: '',
                    bankName: '',
                    email: '',
                    bankCode: '',
                    routingNumber: '',
                    swiftCode: '',
                    countryCode: '',
                    phoneNumber: '',
                    postalCode: '',
                    state: '',
                    city: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {() => (
                    <Form className="flex gap-2">
                      <div className="flex flex-col items-center gap-2 relative top-2">
                        <div>
                          <img src={twoIcon} alt="" />
                        </div>
                        <div className="border-[1.9px] border-[rgb(17,116,237)] h-[100%]"></div>
                      </div>
                      <div className="flex flex-col w-[100%]">
                        <h1 className="font-[900] text-[18px] lg:text-[20px] leading-[40px] text-[#343434]">
                          Bank / Client Details
                        </h1>
                        <div className="flex flex-col gap-[25px] border border-[#EAECF0] py-[8px] rounded-[10px] p-[20px]">
                          <p className="pt-[10px] text-[#101828] text-[14px] leading-[20px] font-[500]">
                            Kindly provide the bank details of the beneficiary
                          </p>
                          <div className="flex flex-col lg:flex-row gap-3">
                            <div className="flex flex-col w-full lg:w-[50%] text-[#344054] font-[500] text-[14px] leading-[20px]">
                              <p>Bank Name*</p>
                              <Field
                                type="text"
                                name="bankName"
                                className="border border-[#EAECF0] rounded-[10px] h-[51px] pl-3 w-[100%]"
                              />
                              <ErrorMessage
                                name="bankName"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                            <div className="flex flex-col w-full lg:w-[50%] text-[#344054] font-[500] text-[14px] leading-[20px]">
                              <p>Bank Address*</p>
                              <Field
                                type="text"
                                name="bankAddress"
                                className="border border-[#EAECF0] rounded-[10px] h-[51px] pl-3 w-[100%]"
                              />
                              <ErrorMessage
                                name="bankAddress"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col lg:flex-row gap-3">
                            <div className="flex flex-col w-full lg:w-[50%] text-[#344054] font-[500] text-[14px] leading-[20px]">
                              <p>Country*</p>
                              <Field
                                type="text"
                                name="country"
                                className="border border-[#EAECF0] rounded-[10px] h-[51px] pl-3 w-[100%]"
                              />
                              <ErrorMessage
                                name="country"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                            <div className="flex flex-col w-full lg:w-[50%] text-[#344054] font-[500] text-[14px] leading-[20px]">
                              <p>Swift Code*</p>
                              <Field
                                type="text"
                                name="swiftCode"
                                className="border border-[#EAECF0] rounded-[10px] h-[51px] pl-3 w-[100%]"
                              />
                              <ErrorMessage
                                name="swiftCode"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                          </div>

                          <p className="pt-[20px] text-[#101828] text-[14px] leading-[20px] font-[500]">
                            Kindly provide the address details of the beneficiary
                          </p>
                          <div className="flex flex-col w-[100%] text-[#344054] font-[500] text-[14px] leading-[20px]">
                            <p>Beneficiary Name*</p>
                            <Field
                              type="text"
                              name="beneficiaryName"
                              className="border border-[#EAECF0] rounded-[10px] h-[51px] pl-3 w-[100%]"
                            />
                            <ErrorMessage
                              name="beneficiaryName"
                              component="div"
                              className="text-red-500"
                            />
                          </div>
                          <div className="flex flex-col lg:flex-row gap-3">
                            <div className="flex flex-col w-full lg:w-[50%] text-[#344054] font-[500] text-[14px] leading-[20px]">
                              <p>Account Number/IBAN*</p>
                              <Field
                                type="text"
                                name="accountNumber"
                                className="border border-[#EAECF0] rounded-[10px] h-[51px] pl-3 w-[100%]"
                              />
                              <ErrorMessage
                                name="accountNumber"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                            <div className="flex flex-col w-full lg:w-[50%] text-[#344054] font-[500] text-[14px] leading-[20px]">
                              <p>Account Name*</p>
                              <Field
                                type="text"
                                name="accountName"
                                className="border border-[#EAECF0] rounded-[10px] h-[51px] pl-3 w-[100%]"
                              />
                              <ErrorMessage
                                name="accountName"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col w-[100%] text-[#344054] font-[500] text-[14px] leading-[20px]">
                            <p>Beneficiary Address*</p>
                            <Field
                              type="text"
                              name="beneficiaryAddress"
                              className="border border-[#EAECF0] rounded-[10px] h-[51px] pl-3 w-[100%]"
                            />
                            <ErrorMessage
                              name="beneficiaryAddress"
                              component="div"
                              className="text-red-500"
                            />
                          </div>
                          <div className="flex flex-col w-[100%] text-[#344054] font-[500] text-[14px] leading-[20px]">
                            <p>Sort Code (Optional)*</p>
                            <Field
                              type="text"
                              name="sortCode"
                              className="border border-[#EAECF0] rounded-[10px] h-[51px] pl-3 w-[100%]"
                            />
                          </div>
                          <div className="flex justify-end">
                            <button
                              type="submit"
                              className="bg-[#1174ED] text-[#FCFCFD] font-[600] text-[14px] leading-[20px] rounded-[8px] px-[20px] py-[10px]"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default AddBeneficiaries;
