/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, notification, Select } from 'antd';
import leftArrowIcon from 'assets/Arrow - Left b.svg';
import rightArrowIcon from 'assets/Arrow1 - Left.svg';
import KycSideBar from 'components/KybSideBar';
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { ADDRESS_ROUTE, BASE_ROUTE, DOCUMENT_ROUTE } from 'routes/path';
import { useQuestionnaireMutation } from 'services/kyb';
import { useAppSelector } from 'store';
import * as Yup from 'yup';

const { Option } = Select;

const Questionnaire = () => {
  const navigate = useNavigate();
  const [submitQuestionnaire, { isLoading }] = useQuestionnaireMutation();
  const { kyb } = useAppSelector((state) => state.kyb);

  const validationSchema = Yup.object().shape({
    sendingAmount: Yup.string().required('Expected amount to send is required'),
    selectedItems: Yup.array().min(1, 'At least one country to send to is required'),
    receivingAmount: Yup.string().required('Expected amount to receive is required'),
    receiveMonthly: Yup.array().min(1, 'At least one country to receive from is required'),
  });

  interface ErrorResponse {
    data: {
      message: string;
    };
  }

  const handleSubmitQuestionnaire = async (
    values: FormikValues,
    successMessage: string,
    route: string
  ) => {
    try {
      await submitQuestionnaire({
        expected_amount_to_send_monthly: Number(values.sendingAmount),
        expected_amount_to_receive_monthly: Number(values.receivingAmount),
        countries_to_send_to: values.selectedItems.join(','),
        countries_to_receive_from: values.receiveMonthly.join(','),
      }).unwrap();
      notification.success({
        message: 'Success',
        description: successMessage,
      });
      navigate(route);
    } catch (error: any) {
      const err = error as ErrorResponse;
      if (
        err &&
        typeof err === 'object' &&
        'data' in err &&
        typeof err.data === 'object' &&
        'message' in err.data
      ) {
        notification.error({
          message: 'Error',
          description: err.data.message,
        });
      }
    }
  };

  const handleSaveAndContinueLater = async (values: FormikValues) => {
    await handleSubmitQuestionnaire(values, 'Questionnaire saved successfully', BASE_ROUTE);
  };

  const handleSubmit = async (values: FormikValues) => {
    await handleSubmitQuestionnaire(values, 'Questionnaire submitted successfully', DOCUMENT_ROUTE);
  };

  const OPTIONS = [
    'China',
    'India',
    'Cameroon',
    'US',
    'Singapore',
    'Hong Kong',
    'South Africa',
    'kenya',
  ];

  return (
    <KycSideBar>
      <div className="bg-[#fff] rounded-[10px] sha py-[15px] lg:py-[60px] px-[20px] lg:px-[40px] lg:w-[1030px]">
        <Formik
          initialValues={{
            sendingAmount: kyb.questionaire?.expected_amount_to_send_monthly || [],
            selectedItems: kyb.questionaire?.countries_to_send_to?.split(',') || [],
            receivingAmount: kyb.questionaire?.expected_amount_to_receive_monthly || [],
            receiveMonthly: kyb.questionaire?.countries_to_receive_from?.split(',') || [],
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleBlur, setFieldValue }) => (
            <Form className="flex flex-col justify-between h-[550px]">
              <div className="flex flex-col gap-5 lg:w-[641px]">
                <h1 className="font-[900] text-[18px] lg:text-[28px] leading-[30.61px] text-[#343434]">
                  Business Questionnaire
                </h1>
                <div className="flex flex-col gap-4">
                  <div className="font-[500] text-[16px] leading-[22.4px] flex flex-col gap-2 relative top-4 -mt-4">
                    <p className="text-[#000000]">
                      How much do you expect to send each month<span className="text-[red]">*</span>
                    </p>
                    <Field
                      name="sendingAmount"
                      as={Select}
                      placeholder="Select range"
                      value={values.sendingAmount}
                      onChange={(value: string) => setFieldValue('sendingAmount', value)}
                      onBlur={handleBlur}
                    >
                      <Option value="10">$10</Option>
                      <Option value="100">$100</Option>
                      <Option value="1000">$1000</Option>
                      <Option value="10000">$10000</Option>
                      <Option value="100000">$100000</Option>
                    </Field>
                    <ErrorMessage name="sendingAmount" component="div" className="text-red-500" />
                  </div>
                  <div className="font-[500] text-[16px] leading-[22.4px] flex flex-col gap-2 mt-4">
                    <p className="text-[#000000]">
                      Where would you send money to?
                      <span className="text-[red]">*</span>
                    </p>
                    <Field
                      name="selectedItems"
                      as={Select}
                      mode="multiple"
                      placeholder="Select"
                      value={values.selectedItems}
                      onChange={(value: string) => setFieldValue('selectedItems', value)}
                      onBlur={handleBlur}
                      style={{ width: '100%' }}
                      options={OPTIONS.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                    />
                    <ErrorMessage name="selectedItems" component="div" className="text-red-500" />
                  </div>
                  <div className="font-[500] text-[16px] leading-[22.4px] flex flex-col gap-2">
                    <p className="text-[#000000]">
                      How much do you expect to receive each month?
                      <span className="text-[red]">*</span>
                    </p>
                    <Field
                      name="receivingAmount"
                      as={Select}
                      placeholder="Select range"
                      value={values.receivingAmount}
                      onChange={(value: string) => setFieldValue('receivingAmount', value)}
                      onBlur={handleBlur}
                    >
                      <Option value="10">$10</Option>
                      <Option value="100">$100</Option>
                      <Option value="1000">$1000</Option>
                      <Option value="10000">$10000</Option>
                      <Option value="100000">$100000</Option>
                    </Field>
                    <ErrorMessage name="receivingAmount" component="div" className="text-red-500" />
                  </div>
                  <div className="font-[500] text-[16px] leading-[22.4px] flex flex-col gap-2">
                    <p className="text-[#000000]">
                      Where would you receive money from?<span className="text-[red]">*</span>
                    </p>
                    <Field
                      name="receiveMonthly"
                      as={Select}
                      mode="multiple"
                      placeholder="Select"
                      value={values.receiveMonthly}
                      onChange={(value: string) => setFieldValue('receiveMonthly', value)}
                      onBlur={handleBlur}
                      style={{ width: '100%' }}
                      options={OPTIONS.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                    />
                    <ErrorMessage name="receiveMonthly" component="div" className="text-red-500" />
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center mt-[50px] lg:w-[641px]">
                <button
                  className="flex items-center gap-1 text-[#1174ED] font-[700] leading-[22.4px] text-[16px]"
                  type="button"
                  onClick={() => handleSaveAndContinueLater(values)}
                >
                  <img src={leftArrowIcon} alt="left arrow icon" />
                  Save <span className="hidden lg:block">and continue later</span>
                </button>
                <div className="flex items-center gap-2">
                  <Link to={ADDRESS_ROUTE}>
                    <button className="flex justify-center items-center gap-2 font-[700] text-[14px] leading-[18.86px] py-[7px] px-[20px] h-[40px] border border-[#1174ED] bg-inherit text-[#1174ED] rounded-[10px]">
                      <img src={leftArrowIcon} alt="left arrow icon" />
                      Previous
                    </button>
                  </Link>
                  <Button
                    type="primary"
                    className="flex justify-center items-center gap-2 font-[700] text-[14px] leading-[18.86px] py-[7px] px-[20px] h-[40px] bg-[#1174ED] text-[#fff] rounded-[10px]"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Next
                    <img src={rightArrowIcon} alt="right arrow icon" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </KycSideBar>
  );
};

export default Questionnaire;
