import { FC } from 'react';

const AccountIcon: FC = () => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.1711 12.6756H16.2845C14.8692 12.6756 13.7216 11.5279 13.7216 10.1117C13.7216 8.69651 14.8692 7.54889 16.2845 7.54889H20.1407"
      stroke="#09090A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.722 10.0532H16.4249"
      stroke="#09090A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.60619 6.14373H10.6662"
      stroke="#09090A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.7141 10.2532C1.7141 3.8484 4.03886 1.71411 11.015 1.71411C17.9903 1.71411 20.315 3.8484 20.315 10.2532C20.315 16.657 17.9903 18.7922 11.015 18.7922C4.03886 18.7922 1.7141 16.657 1.7141 10.2532Z"
      stroke="#09090A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AccountIcon;
