/* eslint-disable @typescript-eslint/no-explicit-any */
import Logo from 'assets/astrabiz-logo.png';
import ErrorCard from 'components/ErrorCard';
import Loader from 'components/Loader';
import Toast from 'components/Toast';
import { TOKEN_COOKIE } from 'constants/const';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_ROUTE } from 'routes/path';
import {
  useForgotPasswordMutation,
  useLoginMutation,
  useResendOtpMutation,
  useResetPasswordMutation,
  useVerifyOtpMutation,
} from 'services/auth';
import cookie from 'services/cookie';
import { useAppDispatch } from 'store';
import { setEmail, setPassword as setStorePassword } from 'store/auth';
import { maskEmail } from 'utils/maskEmail';
import AuthLayout from 'views/auth/AuthLayout';
import VerifyEmail from 'views/auth/VerifyEmail';

const ForgotPassword = () => {
  const [email, setEmailAddress] = useState('');
  const [page, setPage] = useState(0);
  const [error, setError] = useState<any>();
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [status, setStatus] = useState(0);
  const [source, setSource] = useState('login');

  const [forgotPassword, { isLoading: isLoadingForgotPassword }] = useForgotPasswordMutation();
  const [verifyOtp, { isLoading: isLoadingVerifyOtp }] = useVerifyOtpMutation();
  const [resetPassword, { isLoading: isLoadingResetPassword }] = useResetPasswordMutation();
  const [login] = useLoginMutation();
  const [resendOtp] = useResendOtpMutation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleShowToast = (message: string) => {
    setShowToast(true);
    setToastMessage(message);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const handleForgotPassword = (data: { email: string }) => {
    forgotPassword(data)
      .unwrap()
      .then(() => {
        setPage(1);
        setError(null);
      })
      .catch((error) => setError(error));
  };

  const handleVerifyOtp = (data: { email: string; otp_code: string }) => {
    verifyOtp(data)
      .unwrap()
      .then(() => {
        setPage(2);
        setError(null);
      })
      .catch((error) => setError(error));
  };

  const handleResetPassword = (data: { email: string; password: string; otp_code: string }) => {
    if (!error) {
      resetPassword(data)
        .unwrap()
        .then(() => {
          login({ email: email, password: password })
            .unwrap()
            .then((payload) => {
              dispatch(setEmail(data.email));
              dispatch(setStorePassword(data.password));
              if (payload.data.is_email_verified === false) {
                setSource('register');
                resendOtp({ email: data.email });
                setPage(3);
              } else if (payload.data.access_token) {
                cookie.set(TOKEN_COOKIE, {
                  ...payload.data,
                });
                navigate(BASE_ROUTE);
              }
            })
            .catch(() => {
              handleShowToast('error logging in');
              setStatus(0);
            });
          setError(null);
        })
        .catch((error) => setError(error));
    }
  };

  useEffect(() => {
    document.title = 'AstraBiz | ForgotPassword';
  }, []);

  useEffect(() => {
    page === 2 && password !== confirmPassword ? setError('Passwords do not match') : setError('');
  }, [confirmPassword, page, password]);

  return (
    <AuthLayout>
      <div className={`${page !== 3 ? 'p-6 mx-auto w-[80%]' : null}`}>
        <img src={Logo} alt="astravest-logo" className={`${page === 3 && 'hidden'}`} />
        {showToast && <Toast status={status} message={toastMessage} onClose={handleCloseToast} />}
        {!page ? (
          <div>
            <p className="mt-16 text-2xl font-black">Forgot Password</p>
            <p className="mt-5 font-medium">
              Kindly input your email you used to register to reset your password{' '}
            </p>
            {error ? <ErrorCard text={error.data.errors} /> : null}

            <form
              className="mt-10 grid"
              onSubmit={(e) => {
                e.preventDefault();
                handleForgotPassword({ email: email });
              }}
            >
              <label htmlFor="email" className="font-medium">
                Email
              </label>
              <input
                name="email"
                className="border mt-2 p-3 rounded-xl placeholder:text-sm"
                placeholder="example@example.com"
                type="email"
                value={email}
                onChange={(e) => setEmailAddress(e.target.value)}
              />

              {isLoadingForgotPassword ? (
                <Loader className="mt-8" />
              ) : (
                <button
                  type="submit"
                  className="mt-8 w-full text-center bg-astravest-blue text-white py-3 rounded-xl text-base font-bold"
                >
                  Continue
                </button>
              )}
            </form>
          </div>
        ) : page === 1 ? (
          <div>
            <p className="mt-16 text-2xl font-black">Reset Password</p>
            <p className="mt-5 font-medium">
              Kindly input the OTP sent to your mail {maskEmail(email)} to reset your password
            </p>
            {error ? <ErrorCard text={error.data.errors} /> : null}

            <form
              className="mt-10 grid"
              onSubmit={(e) => {
                e.preventDefault();
                handleVerifyOtp({ email: email, otp_code: otp });
              }}
            >
              <label htmlFor="otp" className="font-medium">
                OTP
              </label>
              <input
                name="otp"
                className="border mt-2 p-3 rounded-xl placeholder:text-sm"
                placeholder="***********"
                type="password"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />

              {isLoadingVerifyOtp ? (
                <Loader className="mt-8" />
              ) : (
                <button
                  type="submit"
                  className="mt-8 w-full text-center bg-astravest-blue text-white py-3 rounded-xl text-base font-bold"
                >
                  Continue
                </button>
              )}
            </form>
          </div>
        ) : page === 2 ? (
          <div>
            <p className="mt-16 text-2xl font-black">Reset Password</p>
            <p className="mt-5 font-medium">Input your new password to reset your password</p>
            {error ? (
              <ErrorCard text={typeof error === 'string' ? error : error.data.errors} />
            ) : null}

            <form
              className="mt-10 grid"
              onSubmit={(e) => {
                e.preventDefault();
                handleResetPassword({ email: email, password: password, otp_code: otp });
              }}
            >
              <label htmlFor="password" className="font-medium">
                New Password
              </label>
              <input
                name="password"
                className="border mt-2 p-3 rounded-xl placeholder:text-sm"
                placeholder="***********"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <div className="grid mt-5">
                <label htmlFor="confirm-password" className="font-medium">
                  Confirm New Password
                </label>
                <input
                  name="confirm-password"
                  className="border mt-2 p-3 rounded-xl placeholder:text-sm"
                  placeholder="***********"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>

              {isLoadingResetPassword ? (
                <Loader className="mt-8" />
              ) : (
                <button
                  type="submit"
                  className="mt-8 w-full text-center bg-astravest-blue text-white py-3 rounded-xl text-base font-bold"
                >
                  Change Password
                </button>
              )}
            </form>
          </div>
        ) : (
          <VerifyEmail from={source} />
        )}
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
