import copyIcon from 'assets/copy-svgrepo-com.svg';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

const BankDetails = () => {
  const accountNumber = '1304266761';
  const handleCopy = useCopyToClipboard(accountNumber);

  return (
    <div className="flex flex-col lg:flex-row justify-between gap-[20px]">
      <div className="flex flex-col gap-5 lg:w-[50%] lg:h-[269px] border-[0.5px] border-[#EAECF0] rounded-[10px] p-[15px]">
        <div className="flex justify-between md:items-center">
          <h1 className="font-[900] text-[18px] lg:text-[24px] text-[#343434] leading-[40px]">
            Bank Details
          </h1>
          <div className="flex items-center gap-2 text-[#8894EC] font-[700] text-[12.57px] leading-[18.86px]">
            <button className="btn bg-[#8894EC33] border border-[#8894EC] rounded-[30px] w-[100% ] md:w-[79px] h-[33px] flex justify-center items-center py-[20px] px-[7px]">
              Local
            </button>
            <button className="btn border border-[#8894EC] rounded-[30px] w-[100%] md:w-[79px] h-[33px] flex justify-center items-center py-[20px] px-[7px]">
              Global
            </button>
          </div>
        </div>
        <div className="flex justify-between gap-[30px] md:gap-0">
          <div className="flex flex-col gap-3 w-[40%] whitespace-nowrap md:whitespace-normal">
            <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">Account Name</p>
            <h1 className="text-[#101828] font-[700] text-[10px] md:text-[14px] leading-[20px]">
              ADASTRA TECH LIMITED
            </h1>
          </div>
          <div className="flex flex-col gap-3 w-[60%]">
            <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">Account Number</p>
            <div className="flex items-center gap-2">
              <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
                {accountNumber}
              </h1>
              <button onClick={handleCopy}>
                <img src={copyIcon} alt={copyIcon} />
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3 w-[40%] mt-[20px]">
          <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">Bank Name</p>
          <h1 className="text-[#101828] font-[700] text-[10px] md:text-[14px] leading-[20px]">
            Providus Bank
          </h1>
        </div>
      </div>
      <div className="box flex flex-col gap-3 w-[100%] lg:w-[50%] lg:h-[269px] border-[1px] border-[#1174ED] bg-[#F5F9FF] rounded-[10px] p-[15px]">
        <h1 className="font-[900] text-[12px] md:text-[16px] md:leading-[18px] text-[#343434]">
          Important details
        </h1>
        <div className="flex gap-2">
          <div>
            <div className="md:h-[21px] md:w-[21px] w-[18px] h-[18px] bg-[#1174ED] rounded-[6px] font-[700] flex justify-center items-center text-[#fff]">
              i
            </div>
          </div>
          <div className="text-[#666666] font-[500] text-[10px] md:text-[13px] md:leading-[19px] flex flex-col gap-1">
            <p>
              A service request is a way of quickly communicating your needs or concerns to our
              team. Whether you're seeking assistance, reporting an issue, or requesting a service,
              we're here to help.
            </p>
            <p>
              Your service request has been successfully initiated and is currently under review.
            </p>
            <p>
              Our team is dedicated to addressing your request promptly and efficiently. You can
              expect a follow-up email shortly with further details regarding the progress of your
              request, including any additional information required, estimated timelines, and next
              steps.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
