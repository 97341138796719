import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import store from 'store';
import {
  KybAddShareHolderInput,
  KybQuestionaireInput,
  KybShareHolderVerifyInput,
  KybUpdateInput,
} from 'types/kyb';
import { baseQuery } from 'utils/baseQuery';

const customBaseQuery = fetchBaseQuery({
  baseUrl: baseQuery.baseUrl,
  prepareHeaders: (headers) => {
    if (!headers) {
      headers = new Headers();
    }
    const businessId = store.getState()?.business?.business?.id;
    if (businessId) {
      headers.set('business_id', businessId);
      headers.set('business-id', businessId);
    }
    return baseQuery.prepareHeaders(headers);
  },
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object
) => {
  const result = await customBaseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    clearCookiesAndLogout();
  }

  return result;
};

const clearCookiesAndLogout = () => {
  document.cookie.split('; ').forEach((cookie) => {
    document.cookie = `${cookie.split('=')[0]}=;expires=${new Date(0).toUTCString()};path=/`;
  });

  window.location.href = '/login';
};

export const kybApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: 'kybApi',
  endpoints: (builder) => ({
    updateKyb: builder.mutation({
      query: (payload: KybUpdateInput) => ({
        url: '/kyb/update-business-information',
        method: 'PATCH',
        body: payload,
      }),
    }),
    questionnaire: builder.mutation({
      query: (payload: KybQuestionaireInput) => ({
        url: '/kyb/create-questionaire',
        method: 'PATCH',
        body: payload,
      }),
    }),
    kybUpload: builder.mutation({
      query: (formData) => ({
        url: '/kyb/upload-business-documents',
        method: 'POST',
        body: formData,
      }),
    }),
    addShareHolder: builder.mutation({
      query: (payload: KybAddShareHolderInput) => ({
        url: '/kyb/add-shareholders',
        method: 'POST',
        body: payload,
      }),
    }),
    shareHolderVerify: builder.mutation({
      query: (payload: KybShareHolderVerifyInput) => ({
        url: '/kyb/shareholder-kyc-verification',
        method: 'POST',
        body: payload,
      }),
    }),
    getKYB: builder.mutation({
      query: () => ({
        url: '/kyb',
        method: 'GET',
      }),
    }),
    submitKYB: builder.mutation({
      query: () => ({
        url: '/kyb/submit-kyb',
        method: 'PATCH',
      }),
    }),
    deleteKYB: builder.mutation({
      query: ({ shareholderId }) => ({
        url: `/kyb/delete-shareholder/${shareholderId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useUpdateKybMutation,
  useQuestionnaireMutation,
  useKybUploadMutation,
  useAddShareHolderMutation,
  useShareHolderVerifyMutation,
  useGetKYBMutation,
  useSubmitKYBMutation,
  useDeleteKYBMutation,
} = kybApi;
