import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TOKEN_COOKIE } from 'constants/const';
import cookie from 'services/cookie';
import { baseQuery } from 'utils/baseQuery';

const customBaseQuery = fetchBaseQuery({
  baseUrl: baseQuery.baseUrl,
  prepareHeaders: (headers) => {
    return baseQuery.prepareHeaders(headers);
  },
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object
) => {
  const result = await customBaseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    clearCookiesAndLogout();
  }

  return result;
};

const clearCookiesAndLogout = () => {
  document.cookie.split('; ').forEach((cookie) => {
    document.cookie = `${cookie.split('=')[0]}=;expires=${new Date(0).toUTCString()};path=/`;
  });

  window.location.href = '/agent/login';
};

export const agentApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: 'agentApi',
  endpoints: (builder) => ({
    getAgent: builder.mutation({
      query: () => ({
        url: '/agent/auth/agent',
        method: 'GET',
      }),
    }),
    getAgentWallet: builder.mutation({
      query: () => ({
        url: '/agent/wallets',
        method: 'GET',
      }),
    }),
    getAgentTransaction: builder.mutation({
      query: () => ({
        url: '/agent/transactions',
        method: 'GET',
      }),
    }),
    processTransaction: builder.mutation({
      query: ({ transactionsId }) => ({
        url: `/agent/transactions/${transactionsId}/process`,
        method: 'PATCH',
      }),
    }),
  }),
});

export const {
  useGetAgentMutation,
  useGetAgentWalletMutation,
  useGetAgentTransactionMutation,
  useProcessTransactionMutation,
} = agentApi;

export function getAuthToken(): { access_token: string } | null {
  return cookie.get(TOKEN_COOKIE);
}
