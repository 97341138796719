import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { AuthState, User } from 'types/auth';

const initialState: AuthState = {
  email: '',
  password: '',
  user: {} as User,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload;
    },
    setPassword: (state, { payload }: PayloadAction<string>) => {
      state.password = payload;
    },
    setUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },
  },
});

export const authSelector = (state: RootState) => state.auth;

export const { setEmail, setPassword, setUser } = authSlice.actions;

export default authSlice.reducer;
