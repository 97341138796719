/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect } from 'react';
import {
  Location,
  NavigateFunction,
  RoutesProps,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { BASE_ROUTE, LOGIN_ROUTE } from 'routes/path';
import { getAuthToken } from 'services/auth';
import { useAppDispatch } from 'store';
import { setLayoutPageLoader } from 'store/layout';
import { AppRouteConfig } from 'types/navigation';
import { getCurrentRouteContext } from 'utils/route';
// import { getConfigurations } from 'store/configurations'
// import { getAccount } from 'store/account'

let user: { access_token: string } | null;
let matched: AppRouteConfig;

const checkJwtAuth = async (location: Location, navigate: NavigateFunction) => {
  // TODO: Send token to server to check if token is valid
  user = getAuthToken();

  matched = getCurrentRouteContext(location);

  if (matched.isError) {
    navigate(matched.path!);
  }

  if (matched.isolated) {
    return null;
  }

  /**
   * User is authenticated but they are trying to access a public view
   * OR
   * User is authenticated and trying to access the app
   */
  if ((!matched.restricted && user) || (matched.restricted && user)) {
    return redirectToApp(navigate);
  }

  /**
   * User is not authenticated but they are trying to access a restricted view
   * OR
   * User is not authenticated and they are trying to access a public
   * view
   */
  if ((matched.restricted && !user) || (!matched.restricted && !user)) {
    return redirectToLogin(navigate);
  }

  return null;
};

const redirectToLogin = (navigate: NavigateFunction) => {
  if (matched.restricted && !user) {
    return navigate(LOGIN_ROUTE);
  }
};
const redirectToApp = (navigate: NavigateFunction) => {
  /**  User is trying to access a public view but they are already
   * authenticated
   * */
  if (!matched.restricted && user) {
    return navigate(BASE_ROUTE);
  }
};

const Initialization = (props: RoutesProps) => {
  const { children } = props;
  const location = useLocation();
  const dispatch = useAppDispatch();

  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    checkJwtAuth(location, navigate).then(() => {
      if (user) {
        dispatch(setLayoutPageLoader(true));

        dispatch(setLayoutPageLoader(false));
      }
    });
  }, [dispatch, location, navigate]);

  return children;
};

export default Initialization;
