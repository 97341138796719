import { FC } from 'react';

const BenefitIcon: FC = () => (
  <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9725 17.3682C7.7335 17.3682 4.9665 16.8782 4.9665 14.9162C4.9665 12.9542 7.7155 11.2462 10.9725 11.2462C14.2115 11.2462 16.9785 12.9382 16.9785 14.8992C16.9785 16.8602 14.2295 17.3682 10.9725 17.3682Z"
      stroke="#09090A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9725 8.44869C13.0985 8.44869 14.8225 6.72569 14.8225 4.59969C14.8225 2.47369 13.0985 0.749695 10.9725 0.749695C8.8465 0.749695 7.1225 2.47369 7.1225 4.59969C7.1165 6.71769 8.8265 8.44169 10.9455 8.44869H10.9725Z"
      stroke="#09090A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3622 7.3916C18.5992 7.0606 19.5112 5.9326 19.5112 4.5896C19.5112 3.1886 18.5182 2.0186 17.1962 1.7486"
      stroke="#09090A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9432 10.5444C19.6972 10.5444 21.1952 11.7334 21.1952 12.7954C21.1952 13.4204 20.6782 14.1014 19.8942 14.2854"
      stroke="#09090A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5838 7.3916C3.3458 7.0606 2.4338 5.9326 2.4338 4.5896C2.4338 3.1886 3.4278 2.0186 4.7488 1.7486"
      stroke="#09090A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.0018 10.5444C2.2478 10.5444 0.749802 11.7334 0.749802 12.7954C0.749802 13.4204 1.2668 14.1014 2.0518 14.2854"
      stroke="#09090A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BenefitIcon;
