import type { TableColumnsType } from 'antd';
import { notification, Popconfirm, Spin, Table } from 'antd';
import leftArrowIcon from 'assets/Arrow - Left Square.svg';
import rightArrowIcon from 'assets/Arrow - Right Square.svg';
import ngnIcon from 'assets/flag-for-nigeria-svgrepo-com 1.svg';
import usdIcon from 'assets/Group 1171275979.svg';
import hkdIcon from 'assets/hong-kong-flag-round-circle-icon.svg';
import refreshIcon from 'assets/ic_round-refresh.svg';
import cynIcon from 'assets/Mask group.svg';
import plusIcon from 'assets/plus.svg';
import searchIcon from 'assets/search.svg';
import deleteIcon from 'assets/tag.svg';
import AddBeneficiaries from 'components/userComponent/AddBeneficiaryModal';
import SideBar from 'components/userComponent/SideBar';
import useTableScroll from 'components/useTableScroll';
import { useEffect, useState } from 'react';
import { useDeleteBeneficiaryMutation, useGetBeneficiaryMutation } from 'services/beneficiaries';
import { useAppDispatch } from 'store';
import { setBeneficiary } from 'store/beneficiaries';
import { DataType } from 'types/beneficiaries';

const Beneficiaries = () => {
  const [getBeneficiary] = useGetBeneficiaryMutation();
  const [deleteBeneficiary] = useDeleteBeneficiaryMutation();
  const scrollX = useTableScroll();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState<DataType[]>([]);
  const [filteredData, setFilteredData] = useState<DataType[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [isRotated, setIsRotated] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const getCurrencyIcon = (currency: string): string | undefined => {
    switch (currency) {
      case 'USD':
        return usdIcon;
      case 'NGN':
        return ngnIcon;
      case 'CNY':
        return cynIcon;
      case 'HKD':
        return hkdIcon;
      default:
        return undefined;
    }
  };

  const getCountryByCurrency = (currency: string): string => {
    switch (currency) {
      case 'USD':
        return 'United States';
      case 'NGN':
        return 'Nigeria';
      case 'CNY':
        return 'China';
      case 'HKD':
        return 'Hong Kong';
      default:
        return '';
    }
  };

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Approved':
        return { backgroundColor: '#ECFDF3', color: '#037847', dotColor: '#037847' };
      default:
        return {};
    }
  };

  const formatAccountNumber = (accountNumber: string) => {
    if (!accountNumber) return '';
    if (accountNumber.length <= 6) return accountNumber;
    const firstThree = accountNumber.slice(0, 3);
    const lastThree = accountNumber.slice(-3);
    return `${firstThree}****${lastThree}`;
  };

  const fetchBeneficiaries = () => {
    setLoading(true);
    getBeneficiary({})
      .unwrap()
      .then((response) => {
        const formattedData = response.data.map(
          (item: {
            id: string;
            account_name: string;
            currency: string;
            address: { country_code: string };
            account_number: string;
            bank_name: string;
          }) => ({
            key: item.id,
            account_name: item.account_name,
            currency: item.currency,
            country: getCountryByCurrency(item.currency),
            account_number: formatAccountNumber(item.account_number),
            bank_name: item.bank_name,
            status: 'Approved',
            delete: deleteIcon,
          })
        );
        setData(formattedData);
        setFilteredData(formattedData);
        dispatch(setBeneficiary(response.data));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBeneficiaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredData(
      data.filter((item) => item.account_name.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }, [searchQuery, data]);

  const handleDelete = (key: React.Key) => {
    deleteBeneficiary({ beneficiaryId: key })
      .unwrap()
      .then(() => {
        setData((prevData) => prevData.filter((item) => item.key !== key));
        notification.success({
          message: 'Success',
          description: 'Beneficiary Deleted.',
        });
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const modalClose = () => {
    setIsModalOpen(false);
  };

  const handleRefresh = () => {
    setIsRotated(!isRotated);
    fetchBeneficiaries();
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Account Name',
      dataIndex: 'account_name',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      render: (text) => (
        <span className="flex relative top-2 -mt-2 items-center gap-1">
          <img src={getCurrencyIcon(text)} alt={text} className="h-[23.56px] w-[23.56px]" />
          {text}
        </span>
      ),
    },
    {
      title: 'Country',
      dataIndex: 'country',
    },
    {
      title: 'Account Number',
      dataIndex: 'account_number',
      render: (text) => formatAccountNumber(text),
    },
    {
      title: 'Bank Name',
      dataIndex: 'bank_name',
    },
    {
      title: 'Labels/Tag',
      dataIndex: 'status',
      render: (text) => {
        const { backgroundColor, color, dotColor } = getStatusStyle(text);
        return (
          <div
            style={{
              backgroundColor,
              color,
              padding: '2px 8px 2px 6px',
              borderRadius: '16px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80%',
              height: '22px',
            }}
          >
            <div
              style={{
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                backgroundColor: dotColor,
                marginRight: '8px',
              }}
            ></div>
            {text}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'delete',
      render: (_, record) => (
        <Popconfirm
          title="Delete this beneficiary?"
          onConfirm={() => handleDelete(record.key)}
          okText="Yes"
          cancelText="No"
          trigger="hover"
        >
          <button>
            <img src={deleteIcon} alt="Delete" style={{ cursor: 'pointer' }} />
          </button>
        </Popconfirm>
      ),
    },
  ];

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / pageSize)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <SideBar isKycVerified={true}>
      <div className="h-[100vh]">
        <div className="flex flex-col gap-3 border-[0.5px] border-[#EAECF0] rounded-[10px]">
          <div className="flex justify-between border-b-[0.5px] border-b-[#EAECF0] pb-[15px] pt-[15px] px-[15px] w-[100%]">
            <div className="w-[20%]">
              <h1 className="font-[900] text-[#343434] text-[18px] lg:text-[24px] leading-[40px]">
                Beneficiaries
              </h1>
            </div>
            <div className="flex items-center justify-between md:w-[80%]">
              <div className="lg:flex hidden gap-4 font-[700] text-[12.57px] leading-[18.86px] text-[#8894EC]">
                <button className="flex justify-center items-center border border-[#8894EC] rounded-[30px] h-[33px] py-[7px] px-[20px]">
                  Your Accounts
                </button>
                <button className="flex justify-center items-center border border-[#8894EC] bg-[#8894EC33] rounded-[30px] h-[33px] py-[7px] px-[20px]">
                  Recipients
                </button>
              </div>
              <div className="input md:flex items-center hidden">
                <img
                  src={searchIcon}
                  alt={searchIcon}
                  className="relative left-[30px] lg:left-[30px] lg:-ml-[30px]"
                />
                <input
                  type="text"
                  placeholder="Search for Beneficiary"
                  className="bg-[#F5F5F5] pl-[35px] rounded-[10px] h-[40px] py-[16.21px]"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <button
                onClick={handleRefresh}
                className="hidden md:flex items-center font-[500] text-[10px] md:text-[14px] leading-[20px]"
              >
                <img
                  src={refreshIcon}
                  alt={refreshIcon}
                  className={`transition-transform duration-500 ease-in-out h-[20px] md:h-[100%] ${
                    isRotated ? 'rotate-360' : ''
                  }`}
                />
                <p>Refresh</p>
              </button>
              <div>
                <button
                  onClick={showModal}
                  className="flex items-center justify-center gap-[8px] bg-[#1174ED] h-[40px] md:w-[146px] px-[5px] md:px-[7px] py-[20px] rounded-[10px] border-[#1174ED] text-[#FFFFFF] font-[400] text-[10px] md:text-[14px] leading-[18.86px]"
                >
                  <img src={plusIcon} alt={plusIcon} />
                  Add Account
                </button>
              </div>
              <AddBeneficiaries
                isOpen={isModalOpen}
                modalClose={modalClose}
                fetchBeneficiaries={fetchBeneficiaries}
              />
            </div>
          </div>

          {loading ? (
            <div className="flex justify-center items-center" style={{ minHeight: '150px' }}>
              <Spin size="large" />
            </div>
          ) : (
            <div className="flex flex-col">
              <Table
                columns={columns}
                dataSource={filteredData}
                size="middle"
                className="benefit_table"
                id="table"
                pagination={{
                  current: currentPage,
                }}
                scroll={{
                  x: scrollX,
                }}
              />
              {filteredData.length > pageSize && (
                <div className="text-[#1174ED] font-[700] text-[14px] leading-[18.9px] flex justify-end py-[10px] pr-[20px] items-center gap-2">
                  <button className="mx-1 cursor-pointer" onClick={handlePrevPage}>
                    <img src={leftArrowIcon} alt={leftArrowIcon} />
                  </button>
                  <button className="mx-1 cursor-pointer" onClick={handleNextPage}>
                    <img src={rightArrowIcon} alt={rightArrowIcon} />
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </SideBar>
  );
};

export default Beneficiaries;
