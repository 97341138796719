import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { LayoutState } from 'types/layout';

const initialState: LayoutState = {
  pageLoader: false,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setLayoutPageLoader: (state, { payload }: PayloadAction<boolean>) => {
      state.pageLoader = payload;
    },
  },
});

export const { setLayoutPageLoader } = layoutSlice.actions;

export const layoutSelector = (state: RootState) => state.layout;

export default layoutSlice.reducer;
