import { createElement } from 'react';
import { AGENT_ROUTE_BASE, AGENT_TRANSACTION_ROUTE, AGENT_WALLET_ROUTE } from 'routes/path';
import { AppRouteConfig } from 'types/navigation';
import AgentDashboard from 'views/agent/Dashboard';
import AgentTransaction from 'views/agent/Transaction';
import WalletContainer from 'views/agent/WalletContainer';

const agentRoutes: AppRouteConfig[] = [
  {
    path: AGENT_ROUTE_BASE,
    element: createElement(AgentDashboard),
    restricted: true,
  },
  {
    path: AGENT_WALLET_ROUTE,
    element: createElement(WalletContainer),
    restricted: true,
  },
  {
    path: AGENT_TRANSACTION_ROUTE,
    element: createElement(AgentTransaction),
    restricted: true,
  },
];

export default agentRoutes;
